<template>
  <div>
    <div class="input-wrapper">
      <InputNumber
        class="input-field"
        :placeholder="placeholder"
        :class="inValid ? 'p-invalid ' + inputClass : inputClass"
        :format="format"
        id="integeronly"
        v-model="value"
        :disabled="disable"
        :style="{
          height: `${height}`,
          width: `${width} !important`,
          border: `1px solid ${color} !important`,
          color: `${color}`,
        }"
        @input="onInput"
        allowEmpty
        min="0"
        @keydown="keydown"
        autocomplete="off"
        :prefix="prefix"
        :mode="mode"
      />
    </div>
  </div>
</template>

<script>
import InputNumber from 'primevue/inputnumber';
export default {
  name: 'InputNumbers',
  props: [
    'icon',
    'placeholder',
    'label',
    'height',
    'width',
    'border',
    'color',
    'type',
    'labelClass',
    'value',
    'errorMessages',
    'required',
    'inputClass',
    'disable',
    'maxDigit',
    'prefix',
    'format',
    'mode',
    'inValid',
  ],
  methods: {
    onInput(value) {
      this.editValue = value.value;
      this.$emit('onInput', value.value);
    },
    keydown(e) {
      let max = this.maxDigit ? this.maxDigit : 8;
      if (this.editValue.toString().length > max) {
        e.preventDefault();
        return false;
      }
    },
  },
  components: { InputNumber },
  watch: {
    editValue() {
      this.$emit('change', this.editValue);
    },
  },
  mounted() {
    this.editValue = this.value;
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
:deep(.p-inputnumber.p-invalid.p-component > .p-inputtext) {
  border: 1px solid $pink-color !important;
  border-radius: 4px !important;
}

.input-wrapper {
  display: flex;
  align-items: center;
}
.input-field {
  border-color: unset;
  outline: 0 none;
  outline-offset: 0;
  box-shadow: unset !important;
  border-radius: unset !important;
}

.icons {
  font-weight: bold !important;
  height: 100%;
  font-size: 1.2rem;
  padding: 10px;
  min-width: 40px;
  // border-right: 1px solid #ced4da;
  border: 1px solid #ced4da;
  margin-top: 1rem;
}

.p-inputtext {
  border: 1px solid rgba(2, 0, 56, 0.15);
  border-radius: 4px !important;
  font-family: HK-medium !important;
}
:deep(.p-inputtext) {
  font-family: HK-medium !important;
  width: 100%;
}
// .p-inputtext:enabled:focus {
//   border-color: rgba(2, 0, 56, 0.15);
//   border: 1px solid rgba(2, 0, 56, 0.3);
//   box-shadow: unset;
// }
.p-inputtext:enabled:hover {
  border-color: rgba(2, 0, 56, 0.15);
  border: 1px solid rgba(100, 97, 170, 0.3);
  box-shadow: unset;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.label {
  color: #020038;
}
.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.1rem rgba(2, 0, 56, 0.3) !important;
  border-color: rgba(2, 0, 56, 0.15);
}
</style>
