<template>
  <div>
    <div class="justify-content-end" :class="isMD ? '' : 'flex'">
      <!-- <h1 class="module-title">Applications</h1> -->
      <div class="flex align-items-center mt-3 gap-4">
        <div v-if="list.length" class="export cursor-pointer" @click="download">Export all as CSV</div>
        <Button label="Create New" @click="goToLoanApp" type="primary" />
      </div>
    </div>
    <Applications class="mt-2" @list="getList" />
  </div>
</template>

<script>
import { LOAN_APPLICATION_URL } from '@/constants.api';
import { TOKEN } from '@/constants';
import { mapGetters } from 'vuex';
import Applications from './Applications.vue';
import ScreenSize from '@/mixins/screensize.mixin';
import moment from 'moment';

export default {
  name: 'index',
  mixins: [ScreenSize],
  components: {
    Applications,
  },
  computed: {
    ...mapGetters({
      profileDetails: 'settings/profileDetails',
    }),
  },
  data() {
    return {
      list: [],
    };
  },
  methods: {
    getList(value) {
      this.list = value;
    },
    goToLoanApp() {
      window.open(`${LOAN_APPLICATION_URL}?broker=${this.$cookies.get(TOKEN)}`, '_blank');
    },
    download() {
      let headers = {
        Id: 'ID',
        date: 'Date',
        customer: 'Customer details',
        application: 'Application details',
        stage: 'Loan stage',
        brokerage: 'Brokerage',
        property: 'Buying Property details',
        selling: 'Selling Property details',
      };

      let itemsNotFormatted = this.list.length ? [...this.list] : [];

      let itemsFormatted = [];

      // format the data
      itemsNotFormatted.forEach((item) => {
        let property = '';
        if (item.incomingProperties && item.incomingProperties.length) {
          property =
            item.incomingProperties[0].line1 +
            '' +
            item.incomingProperties[0].city +
            '' +
            item.incomingProperties[0].state +
            '' +
            item.incomingProperties[0].country +
            '' +
            item.incomingProperties[0].postCode;
          property = property.replace(/,/g, '');
        } else {
          property = '';
        }
        let selling = '';
        if (item.outgoingProperties && item.outgoingProperties.length) {
          selling =
            item.outgoingProperties[0].line1 +
            '' +
            item.outgoingProperties[0].city +
            '' +
            item.outgoingProperties[0].state +
            '' +
            item.outgoingProperties[0].country +
            '' +
            item.outgoingProperties[0].postCode;
          selling = selling.replace(/,/g, '');
        } else {
          selling = '';
        }
        let customer = '';
        if (item.customer && item.customer.length && item.customer[0]) {
          customer = item.customer[0];
        } else {
          customer = '-';
        }
        itemsFormatted.push({
          Id: item.Id,
          date: moment(item.createdAt).format('DD/MM/YYYY'),
          customer: customer,
          application: item.calculated_values && item.calculated_values.length ? item.calculatedValue.loanAmount : '',
          stage: item.status,
          brokerage: item.broker_fee_inc_gst + '%' + '' + '$' + item.broker_fee_inc_gst_amount,
          property: property,
          selling: selling,
        });
      });
      var fileTitle = 'applications';
      this.exportCSVFile(headers, itemsFormatted, fileTitle); // call the exportCSVFile() function to process the JSON and trigger the download
    },
    convertToCSV(objArray) {
      var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
      var str = '';

      for (var i = 0; i < array.length; i++) {
        var line = '';
        for (var index in array[i]) {
          if (line != '') line += ',';

          line += array[i][index];
        }

        str += line + '\r\n';
      }
      return str;
    },

    exportCSVFile(headers, items, fileTitle) {
      if (headers) {
        items.unshift(headers);
      }
      // Convert Object to JSON
      var jsonObject = JSON.stringify(items);
      var csv = this.convertToCSV(jsonObject);
      var exportedFilenmae = fileTitle + '.csv' || 'export.csv';
      var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae);
      } else {
        var link = document.createElement('a');
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', exportedFilenmae);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
.export {
  color: rgba(2, 0, 56, 0.4);
  font-weight: bold;
  font-size: 14px;
}
.export:hover {
  color: #2cdd80;
}
:deep(.p-button:enabled:hover) {
  background: #2cdd80 !important;
  color: #ffffff;
  border-color: #2cdd80 !important;
}
:deep(.primary-btn[data-v-82b895d4]) {
  color: #ffffff;
  background: #2cdd80 !important;
  border: unset !important;
  border-radius: 4px;
}
</style>
