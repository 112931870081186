<template>
  <div>
    <div class="content">
      <div class="file" @dragover="dragover" @dragleave="dragleave" @drop="drop" @dragenter="dragenter">
        <input
          type="file"
          name="fields[assetsFieldHandle][]"
          id="assetsFieldHandle"
          class="w-px h-px opacity-0 overflow-hidden absolute"
          @change="onChange"
          ref="fileV"
          accept=".pdf,.jpg,.jpeg,.png"
          :key="fileKey"
        />
        <label for="assetsFieldHandle" class="filelabel">
          <div class="icon-text p-d-flex p-jc-center pt-3">
            <img class="icon" :src="require('@/assets/upload.svg')" alt="" />
            <p class="text">Drag and drop files here or <span>browse</span></p>
          </div>
          <div class="max-file-size pb-3">Max file size: 4MB</div>
        </label>
      </div>
      <div v-if="filelist && filelist.length && filelist[0].name" class="flex title-text justify-content-between mt-3">
        <div class="flex">
          <i class="pi pi-check"></i>
          <div class="file-name">{{ filelist[0].name }}</div>
        </div>
        <div class="delete cursor-pointer" @click="onDelete">Delete</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['files'],
  data() {
    return {
      filelist: [],
      displayDialog: true,
      fileName: '',
      fileKey: 0,
    };
  },
  methods: {
    onChange() {
      if (this.$refs.fileV.files && this.$refs.fileV.files.length) {
        if (this.$refs.fileV.files[0].size > 4000000) {
          this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'File size should be less than 4 MB',
            life: 3000,
          });
          this.$emit('file', []);
        } else {
          this.filelist = [...this.$refs.fileV.files];
          this.$emit('file', this.filelist);
        }
        this.fileKey += 1;
      }
    },
    remove(i) {
      this.filelist.splice(i, 1);
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains('bg-green-300')) {
        event.currentTarget.classList.remove('bg-gray-100');
        event.currentTarget.classList.add('bg-green-300');
      }
    },
    dragenter(event) {
      event.preventDefault();
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add('bg-gray-100');
      event.currentTarget.classList.remove('bg-green-300');
    },
    drop(event) {
      event.preventDefault();
      this.$refs.fileV.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
      // event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove('bg-green-300');
    },
    onDelete() {
      this.filelist = [];
      this.$emit('file', this.filelist);
      this.fileKey += 1;
    },
  },
  created() {
    if (this.files) {
      this.filelist = [];
      this.filelist.push(this.files);
    }
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/global.scss';
[v-cloak] {
  display: none;
}
.title {
  font-size: 1.25rem;
}
.content {
  .heading {
    font-size: 0.875rem;
  }
}
.file {
  margin-top: 1.5rem;
  .icon-text {
    gap: 1rem;
    .text {
      margin: 0;
      font-size: 18px;
      color: #54b7f9;
      font-weight: 600;
      span {
        color: $primary-color;
        text-decoration: underline;
      }
    }
  }
}
.filelabel {
  width: 100%;
  border: 2px dashed #54b7f9;
  background: #54b7f91a;
  border-radius: 5px;
  display: block;
  padding: 1rem 1rem;
  transition: border 300ms ease;
  cursor: pointer;
  text-align: center;
}
.max-file-size {
  font-size: 14px;
  color: #54b7f9;
  font-weight: 500;
}
.title-text {
  color: #020038;
  font-size: 16px;
  font-family: HK-medium;
  width: 100%;
}
.delete {
  color: $primary-color;
}
.file-name {
  max-width: 350px;
}
</style>
