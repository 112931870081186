<template>
  <div class="modal-container">
    <Dialog :visible="display" :modal="true" :style="{ width: '70vw' }">
      <template #header>
        <h1 class="title pm-txt-color bold-600">Accept UnConditional Offer</h1>
      </template>
      <div class="modal-content">
        <p class="desc lt-txt-color bold-600">
          All we need now is to confirm your settlement date and the details of your solicitor/conveyancer.
          <br />Your solicitor/conveyancer will act as a witness for any electronic signing required and will assist you
          through loan settlement.
        </p>

        <div class="icon-wrapper p-d-flex p-ai-center">
          <img :src="require('@/assets/loan_summ_icon.svg')" alt />
          <p class="heading pm-txt-color loan-page">Loan Summary</p>
        </div>
        <div class="section-1 bold-600">
          <div class="grid-items">
            <div class="wrap">
              <p class="heading lt-txt-color">Loan</p>
              <p class="text pm-txt-color">Bridging Home Loan</p>
            </div>
            <div class="wrap">
              <p class="heading lt-txt-color">Interest free period</p>
              <p class="text pm-txt-color">3 months</p>
            </div>
            <div class="wrap">
              <p class="heading lt-txt-color">Valuation Fee</p>
              <p class="text pm-txt-color">$</p>
            </div>
            <div class="wrap">
              <p class="heading lt-txt-color">Total set-up fee (%)</p>
              <p class="text pm-txt-color">1.99%</p>
            </div>
            <div class="wrap">
              <p class="heading lt-txt-color">Term</p>
              <p class="text pm-txt-color">6 months</p>
            </div>
            <div class="wrap">
              <p class="heading lt-txt-color">Interest rate</p>
              <b class="text pm-txt-color">5.99% after interest free period</b>
            </div>
            <div class="wrap">
              <p class="heading lt-txt-color">Loan Deposit</p>
              <p class="text pm-txt-color">$</p>
            </div>
            <div class="wrap">
              <p class="heading lt-txt-color">Loan Amount</p>
              <b class="text primary-color">$1,000,000</b>
            </div>
          </div>
        </div>

        <div class="section-2">
          <p class="sec-heading pm-txt-color font-size-1 bold-600">Solicitor/conveyancer details</p>
          <div class="inputs hk-grotest-medium">
            <TextField width="100%" placeholder="Jake" label="First Name" />
            <TextField width="100%" placeholder="Smith" label="Last Name" />
            <div>
              <label class="p-float-label label hk-grotest-medium"
                >Email <img class="mb-1 ml-1" src="@/assets/info.svg" />
              </label>
              <TextField width="100%" placeholder="jake@techlend.com.au" />
            </div>
          </div>
          <br />
          <div class="inputs hk-grotest-medium">
            <TextField width="100%" placeholder="0401 357 332" label="Phone" />
            <TextField width="100%" placeholder="Techlend" label="Company" />
            <TextField width="100%" placeholder="35 Buckingham Street, Surry Hills, NSW 2010 Aust" label="Address" />
          </div>
        </div>

        <div iv class="section-3">
          <p class="sec-heading pm-txt-color bold-600">Settlement Details</p>
          <div class="inputs hk-grotest-medium" style="margin-left: -10px">
            <DatePicker placeholder="DD/MM/YYYY" class="col col-6" label="Settlement date" />
          </div>
        </div>
      </div>
      <template #footer>
        <div class="modal-foodter bold-600">
          <p class="close" @click="closeDialog">Back</p>
          <Button label="Submit" type="primary" @click="closeDialog" />
        </div>
      </template>
    </Dialog>
  </div>
</template>
<script>
import Dialog from 'primevue/dialog';

export default {
  props: ['display', 'openDialog', 'closeDialog'],
  // data() {
  //   return {
  //     displayBasic: false,
  //   }
  // },
  // methods: {
  //   openBasic() {
  //     this.displayBasic = true
  //   },
  //   closeBasic() {
  //     this.displayBasic = false
  //   },
  // },
  components: { Dialog },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
:deep(::placeholder) {
  color: #020038 !important;
}
:deep(label) {
  color: #020038 !important;
}
:deep(.p-inputtext) {
  font-size: 0.875rem !important;
}
:deep(.p-float-label) {
  font-size: 0.875rem !important;
}
:deep(.hk-grotest-medium) {
  font-family: HK Grotesk !important;
}
.title {
  font-weight: bold;
  color: $black-color;
  font-size: 2.1rem;
}

.desc {
  font-size: 0.875rem;
  margin-bottom: 1.5rem;
  line-height: 2;
}

.text {
  margin-bottom: 0.3rem;
}

.field-not {
  width: 100%;
}

.second-row {
  margin-top: 1rem;
}

.heading,
.text {
  font-size: 0.875rem !important;
}
.loan-page {
  font-size: 1.25rem !important;
}
.inputs {
  gap: 1rem;
  align-items: flex-end;
}

.modal-content {
  border: none;

  .section-1 {
    margin-top: 2rem;
    width: 90%;

    .details-content {
      gap: 1.375rem;
    }

    .grid-items {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 0.5rem;
    }
  }

  .section-2 {
    margin-top: 1.875rem;

    .label {
      margin-bottom: 1rem;
    }

    .inputs {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }

    .second-row {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .section-3 {
    margin-top: 1.875rem;
    width: 100%;

    .mail-total {
      width: 100%;
      margin-top: 1rem;
      gap: 1rem;
    }
  }

  .icon-wrapper {
    gap: 1rem;

    .icon {
      font-size: 2rem;
    }
  }

  .heading {
    margin: 0;
    font-weight: bold;
    font-size: 1.25rem;
  }
}
.modal-foodter {
  gap: 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .close {
    margin: 0;
    cursor: pointer;
  }
}
</style>
