<template>
  <div>
    <h1 class="module-title">Address</h1>
    <div class="mt-3">
      <AddressAutocompleteKleberNew label="Business Address" @onChange="onAddressChange" />
    </div>
    <div v-if="postalcode">
      <TextField
        class="mt-3"
        width="100%"
        label="Street Address"
        placeholder="Address line 1"
        @onInput="getFormData($event, 'line1')"
        :value="formData.line1"
      />
      <TextField
        width="100%"
        placeholder="Address line 2"
        class="mt-2"
        @onInput="getFormData($event, 'line2')"
        :value="formData.line2"
      />

      <div class="flex mt-2">
        <div class="col col-6 mt-3">
          <TextField
            width="95%"
            label="City"
            @onInput="getFormData($event, 'city')"
            :value="formData.city"
            :disable="isCityDisabled"
            placeholder="City"
          />
        </div>
        <div class="ml-2 col col-6 mt-3">
          <Dropdown
            width="96%"
            @change="getFormData($event, 'state')"
            :value="formData.state"
            label="State"
            :items="states"
            :disable="true"
          />
        </div>
      </div>
      <div class="flex mt-2">
        <div class="col col-6 mt-3">
          <div class="p-float-label label hk-grotest-medium">Postal code</div>
          <Inputnumber
            width="95%"
            label="Postal code"
            type="number"
            @onInput="getFormData($event, 'postCode')"
            :value="formData.postCode"
            :disable="isPostalDisabled"
            placeholder="Postal code"
            :maxDigit="3"
            :format="false"
          />
        </div>
        <div class="ml-2 col col-6 mt-3">
          <Dropdown
            width="96%"
            label="Country"
            :items="['Australia']"
            @change="getFormData($event, 'country')"
            :value="formData.country"
            :disable="true"
          />
        </div>
      </div>
    </div>
    <div class="flex align-items-center">
      <Button
        class="mt-4"
        icon="pi pi-chevron-right"
        iconPos="right"
        label="Next"
        type="primary"
        @click="goToNext"
        :disabled="isSubmitDisabled()"
      />
      <div class="back mt-3 ml-4 cursor-pointer" @click="goBack">Back</div>
    </div>
  </div>
</template>

<script>
import { STATES } from '@/constants';
import { mapActions, mapGetters } from 'vuex';
import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import AddressAutocompleteKleberNew from '@/components/common/AddressAutoCompleteKleberNew.vue';

export default {
  name: 'Address',
  components: {
    AddressAutocompleteKleberNew,
  },
  data() {
    return {
      states: STATES,
      postalcode: '',
      formData: {
        line1: '',
        line2: '',
        city: '',
        state: '',
        postCode: '',
        country: '',
      },
      isPostalDisabled: true,
      isCityDisabled: true,
    };
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      postalcode: { required },
      formData: {
        line1: { required },
        city: { required },
        state: { required },
        postCode: { required },
        country: { required },
      },
    };
  },
  methods: {
    ...mapActions(['referralUpdateSubProgressList', 'referralUpdateSelectedSubProgressIndex']),
    ...mapActions({
      setReferralFormData: 'brokerSignup/setReferralFormData',
    }),
    isSubmitDisabled() {
      return this.v$.$invalid;
    },
    getFormData(value, fieldName) {
      this.formData[fieldName] = value;
      this.formData = { ...this.formData };
    },
    goBack() {
      let currentObj = this.referralSubProgressList[this.referralSelectedSubProgressIndex];
      currentObj[0].isActive = true;
      currentObj[0].color = '#54B7F9';
      currentObj[0].isCompleted = false;
      currentObj[1].isActive = false;
      currentObj[1].isCompleted = false;
      currentObj[1].color = 'rgba(255, 255, 255, 0.3)';
      this.referralUpdateSubProgressList(currentObj);
      this.$router.push('/referral-signup/name');
    },
    goToNext() {
      let currentObj = this.referralSubProgressList[this.referralSelectedSubProgressIndex];
      currentObj[2].isActive = true;
      currentObj[2].color = '#54B7F9';
      currentObj[1].isActive = false;
      currentObj[1].isCompleted = true;
      currentObj[1].color = 'rgba(255, 255, 255, 0.3)';
      currentObj[0].isActive = false;
      currentObj[0].color = 'rgba(255, 255, 255, 0.3)';
      this.referralUpdateSubProgressList(currentObj);
      this.setReferralFormData(this.formData);
      this.$router.push('/referral-signup/email');
    },
    async onAddressChange(address) {
      this.postalcode = address.state;
      this.formData.line1 = address.line1 ? address.line1 : '';
      this.formData.line2 = address.line2 ? address.line2 : '';
      this.formData.state = address.state ? address.state : '';
      this.formData.country = address.country ? address.country : '';
      if (address.city) {
        this.formData.city = address.city;
        this.isCityDisabled = true;
      } else {
        this.formData.city = '';
        this.isCityDisabled = false;
      }
      if (address.postCode) {
        this.formData.postCode = Number(address.postCode);
        this.isPostalDisabled = true;
      } else {
        this.formData.postCode = '';
        this.isPostalDisabled = false;
      }
    },
  },
  computed: {
    ...mapGetters(['referralSelectedSubProgressIndex', 'referralSubProgressList']),
    ...mapGetters('brokerSignup', ['referralFormData']),
  },
  created() {
    this.formData.line1 = this.referralFormData.line1 ? this.referralFormData.line1 : '';
    this.formData.line2 = this.referralFormData.line2 ? this.referralFormData.line2 : '';
    this.formData.city = this.referralFormData.city ? this.referralFormData.city : '';
    this.formData.state = this.referralFormData.state ? this.referralFormData.state : '';
    this.formData.country = this.referralFormData.country ? this.referralFormData.country : '';
    this.formData.postCode = this.referralFormData.postCode ? this.referralFormData.postCode : '';
    this.postalcode = this.referralFormData.postCode ? this.referralFormData.postCode : '';
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
.title-text {
  max-width: 350px;
  color: #020038;
  font-size: 16px;
}
.col {
  padding: unset;
}
.back {
  color: rgba(2, 0, 56, 0.4);
  font-weight: bold;
  font-size: 18px;
}
</style>
