<template>
  <Card>
    <template #title>
      <div class="align-left module-sub-title">Applications</div>
    </template>
    <template #content>
      <Spinner v-if="isLoading" />
      <div v-if="!isLoading && !isMD">
        <DataTable
          v-if="!isLoading && !isMD"
          :value="applicationList"
          :totalRecords="statements.length"
          :scrollable="true"
          scrollHeight="400px"
          data-key="loanReferenceNumber"
        >
          <Column field="loanReferenceNumber" header="ID">
            <template #body="{ data }">
              <div class="color-highlight" @click="goToProgress(data)">
                {{ data.loanReferenceNumber }}
              </div>
            </template>
          </Column>
          <Column field="date" header="Date" />
          <Column field="properties" header="Property details">
            <template #body="{ data }">
              <div class="w-full">
                <div v-for="(property, propertyIndex) in data.properties" :key="propertyIndex" class="property">
                  <div v-for="(line, idx) in property" :key="idx" :class="{ 'property-type': idx === 0 }">
                    {{ line }}
                  </div>
                </div>
              </div>
            </template>
          </Column>
          <Column field="applicants" header="Customer details">
            <template #body="{ data }">
              <div class="w-full">
                <div v-for="(applicantName, applicantIndex) in data.applicants" :key="applicantIndex">
                  {{ applicantName }}
                </div>
              </div>
            </template>
          </Column>
          <Column field="calculatedValue" header="Application details">
            <template #body="{ data }">Loan amount: {{ data.loanAmount }}</template>
          </Column>
          <Column class="capital" field="loanStage" header="Loan stage" />
          <Column field="calculatedValue" header="Brokerage">
            <template #body="{ data }">
              <div class="w-full">
                <div class="flex justify-content-between">
                  <span>Rate:</span>
                  <span>{{ data.brokerFeeRate }}</span>
                </div>
                <div class="flex justify-content-between">
                  <span>Amount:</span>
                  <span>{{ data.brokerFeeAmount }}</span>
                </div>
              </div>
            </template>
          </Column>
        </DataTable>
      </div>
      <div v-if="!isLoading && isMD">
        <div v-if="!applicationList.length">No applications found</div>
        <div class="application-card-container" v-for="(item, i) in applicationList" :key="i">
          <div class="flex align-items-center col col-12 gap-4">
            <div class="color-highlight" @click="goToProgress(item)">
              {{ item.loanReferenceNumber }}
            </div>
          </div>
          <div class="full-width">
            <div class="flex full-width">
              <div class="col col-6">
                <div class="title">Date</div>
                <div>{{ item.date }}</div>
              </div>
              <div class="col col-6 capital">
                <div class="title">Loan stage</div>
                <div>{{ item.loanStage }}</div>
              </div>
            </div>
            <div class="flex flex-wrap full-width">
              <div class="col col-12">
                <div class="title">Customer details</div>
                <div v-for="(applicantName, applicantIndex) in item.applicants" :key="applicantIndex">
                  {{ applicantName }}
                </div>
              </div>
              <div class="col col-12">
                <div class="title mb-1">Property details</div>
                <div v-for="(property, propertyIndex) in item.properties" :key="propertyIndex" class="property">
                  <div v-for="(line, idx) in property" :key="idx" :class="{ 'property-type': idx === 0 }">
                    {{ line }}
                  </div>
                </div>
              </div>
              <div class="col col-12">
                <div class="title">Application details</div>
                <div class="flex justify-content-between line-detail">
                  <span>Loan amount:</span>
                  <span>{{ item.loanAmount }}</span>
                </div>
              </div>
              <div class="col col-12">
                <div class="title">Brokerage</div>
                <div class="flex justify-content-between line-detail">
                  <span>Rate:</span>
                  <span>{{ item.brokerFeeRate }}</span>
                </div>
                <div class="flex justify-content-between line-detail">
                  <span>Amount:</span>
                  <span>{{ item.brokerFeeAmount }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="applicationList.length"
        @click="goToApplications"
        class="color-highlight flex justify-content-end mt-3 mr-4 cursor-pointer"
      >
        View all
        <img class="ml-2" src="@/assets/left-arrow.svg" />
      </div>
    </template>
  </Card>
</template>

<script>
import { API, TECHLEND_BE_URL } from '@/constants.api';
import { _get } from '@/services/crudService';
import { formatCurrency, formatDate, formatNumber, fromSnakeCase } from '@/services/formatHelper';
import { mapActions } from 'vuex';
import Card from 'primevue/card';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import ScreenSize from '@/mixins/screensize.mixin';
import moment from 'moment';

export default {
  name: 'Application',
  mixins: [ScreenSize],
  components: {
    Card,
    DataTable,
    Column,
  },
  setup() {
    return {
      formatCurrency,
      formatDate,
      formatNumber,
      fromSnakeCase,
    };
  },
  data() {
    return {
      statements: [],
      isLoading: true,
    };
  },
  computed: {
    applicationList() {
      const formatAddress = (type) => (property) => {
        const line1 = [property.line1, property.line2].filter(Boolean).join(', ');
        const line2 = [property.city, property.state?.toUpperCase()].filter(Boolean).join(', ');

        return [type, line1, line2, property.postCode].filter(Boolean);
      };
      const formatName = (applicant) => [applicant.firstName, applicant.lastName].filter(Boolean).join(' ');

      return this.statements.map((app) => {
        const loanAmount = app.calculatedValue.loanAmount ?? 0;
        const brokerFeeRate = app.calculatedValue.brokerFee ?? 0;
        return {
          id: app.id,
          loanReferenceNumber: app.loanReferenceNumber,
          date: this.formatDate(app.createdAt),
          properties: [
            ...app.incomingProperties.map(formatAddress('Incoming')),
            ...app.outgoingProperties.map(formatAddress('Outgoing')),
          ],
          applicants: app.applicants.map(formatName),
          loanStage: this.fromSnakeCase(app.applicationStatus),
          loanAmount: formatCurrency(loanAmount),
          brokerFeeRate: formatNumber(brokerFeeRate * 100) + '%',
          brokerFeeAmount: formatCurrency(loanAmount * brokerFeeRate),
          application: app,
        };
      });
    },
  },
  methods: {
    ...mapActions({
      setApplicationId: 'applications/setApplicationId',
      setLoanReferenceNumber: 'applications/setLoanReferenceNumber',
    }),
    goToApplications() {
      this.$router.push('/application');
    },
    getName(data) {
      if (!data.applicants?.length) return '';
      return `${data.applicants[0].firstName} ${data.applicants[0].lastName}`;
    },
    async goToProgress(data) {
      await this.setApplicationId(data.id);
      await this.setLoanReferenceNumber(data.loanReferenceNumber);
      if (
        data.status == 'cancelled' ||
        data.loan_status === 'dropped_off' ||
        data.loan_status === 'withdrawn' ||
        data.loan_status === 'rejected'
      ) {
        this.$router.push(`/application/summary/${data.loanReferenceNumber}?progress=false`);
      } else {
        this.$router.push(`/application/progress/${data.loanReferenceNumber}?progress=true`);
      }
    },
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY');
    },
    formatString(str) {
      return str ? str.split('_').join(' ') : '';
    },
  },
  async created() {
    this.isLoading = true;
    const result = await _get(TECHLEND_BE_URL + API.APPLICATIONS.LIST_APPLICATIONS + '?limit=5&offset=0');
    this.statements = result?.data ? result.data.applications : [];
    this.statements.map((statement) => {
      statement.customer = statement.applicants.find((applicant) => applicant.applicantType == 'primary');
      return statement;
    });
    this.isLoading = false;
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

.property + .property {
  margin-top: 1rem;
}
.property-type {
  color: rgba(2, 0, 56, 0.3);
  font-size: 0.75rem;
  text-transform: uppercase;
}

.p-card {
  box-shadow: unset !important;
  border-radius: 4px !important;
  color: $primary-text-color !important;
  font-family: $font-family !important;
}
:deep(.p-card-title) {
  font-size: 1.25rem !important;
}
:deep(.p-card-content) {
  font-size: 1rem !important;
  @media (max-width: 922px) {
    text-align: unset !important;
  }
}
:deep(.p-datatable .p-datatable-thead > tr > th) {
  background: $white-color;
  color: rgba(2, 0, 56, 0.3) !important;
  font-size: 1rem;
}
:deep(.p-datatable .p-datatable-tbody > tr > td) {
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #020038 !important;
  font-family: HK-medium;
  align-items: start;
}

.color-highlight {
  color: $primary-color !important;
  cursor: pointer;
}
:deep(.p-column-title) {
  white-space: nowrap !important;
}

:deep(.p-datatable .p-datatable-tbody > tr) {
  background: #ffffff;
  color: #495057;
  transition: box-shadow 0.2s;
  outline-color: #a6d5fa;
}
:deep(.p-datatable .p-datatable-tbody > tr > td) {
  text-align: left;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  padding-left: 0rem !important;
}
:deep(.p-datatable .p-datatable-thead > tr > th) {
  padding-left: 0rem !important;
}
.application-card-container {
  font-weight: 500;
  font-size: 14px;
  border-bottom: 1px solid rgba(2, 0, 56, 0.15);
  text-align: left !important;
  .title {
    color: rgba(2, 0, 56, 0.4);
  }
}
</style>
