<template>
  <div>
    <h1 class="module-title">Proof of identity</h1>
    <div class="mt-0">
      <div class="col">
        <Dropdown
          width="100%"
          class="mt-3"
          label="Proof of identity"
          :items="['Driver licence', 'Passport']"
          @change="getFormData($event, 'proofType')"
          :value="formData.proofType"
          placeholder="-Select-"
        />
      </div>
    </div>
    <div class="mt-0 align-items-center" :class="isMD ? '' : 'flex'">
      <div class="col" :class="isMD ? '' : 'col-6'">
        <TextField
          :width="isMD ? '100%' : '95%'"
          class="mt-3"
          label="Document number"
          @onInput="getFormData($event, 'proofDocNumber')"
          :value="formData.proofDocNumber"
          placeholder="Your ID document number"
        />
      </div>
      <div class="col mt-3" :class="isMD ? '' : 'col-6 ml-2'">
        <DatePicker
          label="Document expiry date"
          @change="getFormData($event, 'proofDocExpDate')"
          :value="formData.proofDocExpDate"
          placeholder="DD/MM/YYYY"
          yearRange="2022:2122"
        />
      </div>
    </div>
    <div>
      <div class="mt-3 mb-0 title-text hk-grotest-medium">
        Attach a legible copy of your current Australian Driver's Licence or passport.
      </div>
      <Fileupload
        @file="getFiles"
        :key="fileKey"
        :files="formData.proofFileUrl ? { name: formData.proofFileUrlName } : {}"
      />
      <div class="spinner-container" v-if="isLoading">
        <span>Uploading file..</span><Spinner class="flex align-items-center" />
      </div>
    </div>
    <div class="flex align-items-center">
      <Button
        class="mt-4"
        icon="pi pi-chevron-right"
        iconPos="right"
        label="Next"
        type="primary"
        @click="goToNext"
        :disabled="isSubmitDisabled()"
      />
      <div class="back mt-3 ml-4 cursor-pointer" @click="goBack">Back</div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import Fileupload from './Fileupload.vue';
// import { convertFiletoBase64 } from "../../../../services/FileConverttoBase64";
import Screensize from '@/mixins/screensize.mixin';
// import { SERVER_URL_5 } from "../../../../../constants";
// import { _post } from "../../../../services/crudService";

export default {
  name: 'Proof',
  mixins: [Screensize],
  components: {
    Fileupload,
  },
  data() {
    return {
      fileKey: 0,
      isLoading: false,
      formData: {
        proofType: '',
        proofDocNumber: '',
        proofDocExpDate: '',
        proofFileUrl: '',
        proofFileUrlName: '',
      },
    };
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      formData: {
        proofType: { required },
        proofDocNumber: { required },
        proofDocExpDate: { required },
        proofFileUrl: { required },
        proofFileUrlName: { required },
      },
    };
  },
  methods: {
    ...mapActions([
      'referralUpdateSubProgressList',
      'referralUpdateApplicantProgress',
      'referralUpdateSelectedSubProgressIndex',
      'referralUpdateSelectedSubProgressIndex',
      'referralUpdateSelectedApplicantPrgressIndex',
      'referralUpdateIsActiveValue',
      'referralUpdateApplicantProgress',
    ]),
    ...mapActions({
      setReferralFormData: 'brokerSignup/setReferralFormData',
    }),
    isSubmitDisabled() {
      return this.v$.$invalid;
    },
    async getFiles(value) {
      try {
        this.isLoading = true;
        // const url = await convertFiletoBase64(value[0]);
        let mobile = Date.parse(new Date()).toString();
        // TODO: update with V2 API url
        // await _post(SERVER_URL_5 + "upload_documents_before_signup", {
        //   document_name: "Proof of identity",
        //   type: "broker",
        //   is_supporting: false,
        //   applicationId: "",
        //   document: url,
        //   documentType: "file",
        //   mobile: mobile,
        // });
        this.isLoading = false;
        this.formData.proofFileUrlName = value[0].name;
        this.formData.proofFileUrl = mobile;
      } catch (err) {
        this.isLoading = false;
        this.formData.proofFileUrlName = value[0].name;
        this.formData.proofFileUrl = value[0].name;
        this.fileKey += 1;
        await this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Something went wrong, please upload again',
          life: 3000,
        });
      }
    },
    getFormData(value, fieldName) {
      this.formData[fieldName] = value;
      this.formData = { ...this.formData };
    },
    goBack() {
      let currentObj = this.referralSubProgressList[1];
      currentObj[0].isActive = false;
      currentObj[0].color = 'rgba(255, 255, 255, 0.3)';
      this.referralUpdateSubProgressList(currentObj);
      this.referralUpdateSelectedSubProgressIndex(0);
      let prevObj = this.referralSubProgressList[0];
      prevObj[3].isActive = true;
      prevObj[3].color = '#54B7F9';
      prevObj[3].isCompleted = false;
      this.referralUpdateSubProgressList(prevObj);
      this.referralUpdateApplicantProgress(6);
      this.referralUpdateSelectedApplicantPrgressIndex(0);
      this.$router.push('/referral-signup/mobile');
    },
    goToNext() {
      let currentObj = this.referralSubProgressList[this.referralSelectedSubProgressIndex];
      currentObj[0].isCompleted = true;
      currentObj[0].isActive = false;
      currentObj[0].color = '#54B7F9';
      this.referralUpdateSubProgressList(currentObj);
      this.referralUpdateApplicantProgress(44);
      this.referralUpdateSelectedApplicantPrgressIndex(2);
      this.referralUpdateSelectedSubProgressIndex(2);
      this.referralUpdateIsActiveValue({ index1: 2, index2: 0 });
      this.setReferralFormData(this.formData);
      let prevObj = this.referralSubProgressList[this.referralSelectedSubProgressIndex];
      prevObj[0].isCompleted = false;
      prevObj[0].isActive = true;
      prevObj[0].color = '#54B7F9';
      // this.$router.push("/referral-signup/licensing");
      this.$router.push('/referral-signup/company-address');
    },
  },
  computed: {
    ...mapGetters(['referralSelectedSubProgressIndex', 'referralSubProgressList']),
    ...mapGetters('brokerSignup', ['referralFormData']),
  },
  created() {
    this.formData.proofType = this.referralFormData.proofType ? this.referralFormData.proofType : '';
    this.formData.proofDocNumber = this.referralFormData.proofDocNumber ? this.referralFormData.proofDocNumber : '';
    this.formData.proofDocExpDate = this.referralFormData.proofDocExpDate ? this.referralFormData.proofDocExpDate : '';
    this.formData.proofFileUrl = this.referralFormData.proofFileUrl ? this.referralFormData.proofFileUrl : '';
    this.formData.proofFileUrlName = this.referralFormData.proofFileUrlName
      ? this.referralFormData.proofFileUrlName
      : '';
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
.title-text {
  // max-width: 350px;
  color: #020038;
  font-size: 16px;
}
.col {
  padding: unset;
}
.back {
  color: rgba(2, 0, 56, 0.4);
  font-weight: bold;
  font-size: 18px;
}
</style>
