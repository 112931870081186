<template>
  <div>
    <h1 class="module-title" v-if="!isMD">Licensing/registration details</h1>
    <h1 class="module-title" v-else>Licensing/<br />registration details</h1>
    <Dropdown
      width="100%"
      class="mt-3"
      label="Licensing/registration details"
      @change="getFormData($event, 'licensingType')"
      :value="formData.licensingType"
      :items="items"
      placeholder="-Select-"
      :class="isMD ? 'col' : ''"
      optionLabel="name"
      optionValue="value"
    />
    <div class="mt-2" :class="isMD ? '' : 'flex'">
      <div class="col" :class="isMD ? '' : 'col-6'">
        <TextField
          width="100%"
          class="mt-3"
          label="ACL Registered name"
          @onInput="getFormData($event, 'aclRegistrationName')"
          :value="formData.aclRegistrationName"
          placeholder="Your name as registered on ACL"
        />
      </div>
      <div class="col" :class="isMD ? '' : 'col-6 pl-2'">
        <TextField
          class="mt-3"
          width="100%"
          label="ACL number"
          @onInput="getFormData($event, 'aclNumber')"
          :value="formData.aclNumber"
          placeholder="Your ACL number"
        />
      </div>
    </div>
    <div
      class="mt-2"
      :class="isMD ? '' : 'flex'"
      v-if="
        formData.licensingType == 'I am a Credit Representative (CR) of a holder of an Australian Credit Licence (ACL)'
      "
    >
      <div class="col" :class="isMD ? '' : 'col-6'">
        <TextField
          width="100%"
          class="mt-3"
          label="CR number"
          @onInput="getFormData($event, 'crNumber')"
          :value="formData.crNumber"
          placeholder="Your CR number"
        />
      </div>
    </div>
    <div class="flex align-items-center">
      <div @click="goToNext">
        <Button
          class="mt-4"
          icon="pi pi-chevron-right"
          iconPos="right"
          label="Next"
          type="primary"
          :disabled="isSubmitDisabled()"
        />
      </div>
      <div class="back mt-3 ml-4 cursor-pointer" @click="goBack">Back</div>
    </div>
  </div>
</template>

<script>
import { BROKER_LICENSE_TYPE } from '@/constants';
import { integer, minLength, required } from '@vuelidate/validators';
import { mapActions, mapGetters } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import Screensize from '@/mixins/screensize.mixin';
export default {
  name: 'Licensing',
  components: {},
  mixins: [Screensize],
  data() {
    return {
      formData: {
        aclRegistrationName: '',
        aclNumber: '',
        name: '',
        crNumber: '',
        licensingType: '',
      },
      items: BROKER_LICENSE_TYPE,
    };
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    if (
      this.formData.licensingType ==
      'I am a Credit Representative (CR) of a holder of an Australian Credit Licence (ACL)'
    ) {
      return {
        formData: {
          aclRegistrationName: { required },
          aclNumber: {
            required,
            integer,
            minLength: minLength(6),
          },
          crNumber: {
            required,
            integer,
            minLength: minLength(6),
          },
          licensingType: { required },
        },
      };
    } else {
      return {
        formData: {
          aclRegistrationName: { required },
          aclNumber: {
            required,
            integer,
            minLength: minLength(6),
          },
          licensingType: { required },
        },
      };
    }
  },
  methods: {
    ...mapActions([
      'updateSubProgressList',
      'updateApplicantProgress',
      'updateSelectedSubProgressIndex',
      'updateSelectedSubProgressIndex',
      'updateSelectedApplicantPrgressIndex',
      'updateIsActiveValue',
    ]),
    ...mapActions({
      setFormData: 'brokerSignup/setFormData',
    }),
    isSubmitDisabled() {
      return this.v$.$invalid;
    },
    getFormData(value, fieldName) {
      this.formData[fieldName] = value;
      this.formData = { ...this.formData };
      if (
        fieldName === 'licensingType' &&
        value == 'I am a Credit Representative (CR) of a holder of an Australian Credit Licence (ACL)'
      ) {
        this.formData.crNumber = '';
        this.formData.name = '';
      }
    },
    goBack() {
      let currentObj = this.subProgressList[3];
      currentObj[0].isActive = false;
      currentObj[0].color = 'rgba(255, 255, 255, 0.3)';
      this.updateSubProgressList(currentObj);
      this.updateSelectedSubProgressIndex(2);
      let prevObj = this.subProgressList[2];
      prevObj[0].isActive = true;
      prevObj[0].color = '#54B7F9';
      prevObj[0].isCompleted = false;
      this.updateSubProgressList(prevObj);
      this.updateApplicantProgress(53);
      this.updateSelectedApplicantPrgressIndex(2);
      this.$router.push('/signup/verification');
    },
    goToNext() {
      if (!this.v$.$invalid) {
        this.setFormData(this.formData);
        let currentObj = this.subProgressList[2];
        currentObj[0].isActive = false;
        currentObj[0].isCompleted = true;
        currentObj[0].color = '#54B7F9';
        this.updateSubProgressList(currentObj);
        this.updateApplicantProgress(100);
        this.updateSelectedApplicantPrgressIndex(4);
        this.updateSelectedSubProgressIndex(4);
        this.updateIsActiveValue({ index1: 4, index2: 0 });
        this.$router.push('/signup/agreement');
      }
    },
  },
  computed: {
    ...mapGetters(['selectedSubProgressIndex', 'subProgressList', 'isExistingBrokerCompany']),
    ...mapGetters('brokerSignup', ['brokerFormData']),
  },
  created() {
    this.formData.aclRegistrationName = this.brokerFormData.aclRegistrationName
      ? this.brokerFormData.aclRegistrationName
      : '';
    this.formData.aclNumber = this.brokerFormData.aclNumber ? this.brokerFormData.aclNumber : '';
    this.formData.name = this.brokerFormData.name ? this.brokerFormData.name : '';
    this.formData.crNumber = this.brokerFormData.crNumber ? this.brokerFormData.crNumber : '';
    this.formData.licensingType = this.brokerFormData.licensingType ? this.brokerFormData.licensingType : '';
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
.title-text {
  max-width: 350px;
  color: #020038;
  font-size: 16px;
}
.col {
  padding: unset;
}
.back {
  color: rgba(2, 0, 56, 0.4);
  font-weight: bold;
  font-size: 18px;
}
</style>
