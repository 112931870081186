<template>
  <iframe src="https://www.bridgit.com.au/knowledge-hub" frameborder="0"></iframe>
</template>

<script>
export default {};
</script>

<style scoped>
iframe {
  height: 100%;
  width: 100%;
}
</style>
