<template>
  <div class="todo-wrapper">
    <h4 class="title">To-do</h4>
    <Spinner v-if="isLoading" class="flex" />
    <div v-else>
      <div v-if="pendingList.length === 0" class="todo-completed p-d-flex">
        <img class="mb-none" :src="require('@/assets/todo_completed.svg')" alt />
        <p class="lt-txt-color font-size-1">Your application is currently under review</p>
      </div>

      <div v-else class="todo-list">
        <div v-for="task of pendingList" :key="task.id" class="list p-d-flex p-ai-center">
          <div
            :style="
              width < 545 && {
                border: `1.5px solid ${
                  task.status === 'open' ? '#ffbcbd' : task.status == 'review' ? '#a4bfe0' : '#2cdd80'
                } !important`,
                color: `${task.status === 'open' ? '#ffbcbd' : task.status == 'review' ? '#a4bfe0' : '#2cdd80'}`,
                background: '#ffffff',
              }
            "
            @click="mobileCta(task)"
            class="icon-content-wrapper col-7"
          >
            <i class="icon mb-none pi pi-exclamation-circle"></i>
            <div class="content">
              <p class="heading" v-if="task.taskTitle">
                {{ fromSnakeCase(task.taskTitle) }}
              </p>
              <p v-if="task.externalDescription" class="text">{{ task.externalDescription }}</p>
            </div>
          </div>
          <div
            v-if="
              task.crmTask.modalField != 'conditional' &&
              task.crmTask.modalField != 'no action' &&
              task.crmTask.modalField != 'unconditional' &&
              task.crmTask.modalField != 'bank' &&
              task.crmTask.modalField != 'declaration_1' &&
              task.crmTask.modalField != 'declaration_2'
            "
            @click="clickTaskAction(task)"
            class="pr-4 mb-none"
          >
            <Button
              @click="changeDialog(task)"
              class="mt-3 ml-2"
              :label="getButtonText(task)"
              type="primary"
              width="120px"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { TECHLEND_BE_URL } from '@/constants.api';
// import { _put } from '@/services/crudService';
import { fromSnakeCase } from '@/services/formatHelper';
import { mapActions, mapGetters } from 'vuex';

export default {
  props: [
    'openDialog',
    'completed',
    'progressStatus',
    'openUnconditional',
    'openCondtional',
    'openBasic',
    'loanAmountSettled',
    'getProgress',
    'openDischargeRequest',
  ],
  data() {
    return {
      pendingList: [],
      isLoading: true,
      statusTask: '',
      width: '',
    };
  },
  computed: {
    changeLabel() {
      const status = this.statusTask;
      if (status === 'created' || status === 'open') {
        return 'Update';
      } else if (status === 'supporting_documents' || status === 'supporting_document') {
        return 'Upload file';
      } else if (status === 'conditional_offer' || status === 'unconditional_offer') {
        return 'Accept';
      } else if (status === 'loan_settlement') {
        return 'Review';
      } else if (status === 'loan_funded') {
        return 'Submit';
      }
      return 'Review';
    },
    ...mapGetters({
      tasksList: 'applications/tasksList',
      selectedApplication: 'applications/selectedApplication',
    }),
    // changeDialog() {
    //   const status = this.statusTask;
    //   if (status === "created") {
    //     return this.$props.openBasic;
    //   } else if (
    //     status === "supporting_documents" ||
    //     status === "supporting_document"
    //   ) {
    //     return this.$props.openDialog;
    //   } else if (status === "conditional_offer") {
    //     return this.$props.openCondtional;
    //   } else if (status === "unconditional_offer") {
    //     return this.$props.openUnCondtional;
    //   } else if (status === "loan_settlement") {
    //     return this.$props.loanAmountSettled;
    //   } else if (status === "loan_funded") {
    //     return this.$props.openDischargeReq;
    //   }
    //   return "Review";
    // },
  },
  methods: {
    fromSnakeCase,
    ...mapActions({
      fetchLoanProgress: 'applications/fetchLoanProgress',
      fetchLoanStatus: 'applications/fetchLoanStatus',
      setSelectedTaskId: 'applications/setSelectedTaskId',
      setCrmTaskId: 'applications/setCrmTaskId',
      setIsSupporting: 'applications/setIsSupporting',
    }),
    clickTaskAction(task) {
      if (task.crmTask.modalField == 'address') {
        this.setCrmTaskId(task.crmTaskId);
      }
      this.$emit('setSelectedTask', task);
      this.setSelectedTaskId(task.id);
    },
    onResize() {
      this.width = window.innerWidth;
    },
    mobileCta(task) {
      if (
        this.width < 545 &&
        task.crmTask.modalField != 'conditional' &&
        task.crmTask.modalField != 'unconditional' &&
        task.crmTask.modalField != 'bank' &&
        task.crmTask.modalField != 'declaration_1' &&
        task.crmTask.modalField != 'declaration_2'
      ) {
        this.clickTaskAction(task);
        this.changeDialog(task);
      }
    },
    async changeDialog(task) {
      if (task.crmTask.modalField) {
        if (task.crmTask.modalField == 'address') {
          this.setCrmTaskId(task.taskTypeId);
          this.$emit('openBasic');
        } else if (task.crmTask.modalField == 'conditional') {
          this.$emit('openCondtional');
        } else if (task.crmTask.modalField == 'unconditional') {
          this.$emit('openUnCondtional');
        } else if (task.crmTask.modalField == 'supporting' || task.crmTask.modalField == 'upload') {
          this.$emit('setSelectedTask', task);
          this.$emit('taskDetails', task);
          this.setIsSupporting(true);
          this.$emit('openDialog');
        } else if (task.crmTask.modalField == 'discharge') {
          this.$emit('openDischargeRequest');
        } else if (task.crmTask.modalField == 'bank') {
          // console.log('ilion');
        } else {
          // await _put(TECHLEND_BE_URL + 'brokers/tasks/' + task.id, {
          //   applicationId: this.applicationId,
          //   status: 'review',
          // });
          // this.$emit('refresh');
        }
      } else {
        if (task.status == 'open') {
          this.setIsSupporting(false);
          this.$emit('openDialog');
        } else {
          // await _put(TECHLEND_BE_URL + 'brokers/tasks/' + task.id, {
          //   applicationId: this.applicationId,
          //   status: 'review',
          // });
          // this.$emit('refresh');
        }
      }
    },

    /**
     * Determines the text to use for the button based on the task's CRM Task
     */
    getButtonText(task) {
      if (task.crmTask.modalField) {
        if (task.crmTask.modalField == 'address') {
          return 'Update';
        } else if (task.crmTask.modalField == 'conditional' || task.crmTask.modalField == 'unconditional') {
          return 'Accept';
        } else if (task.crmTask.modalField == 'supporting' || task.crmTask.modalField == 'upload') {
          return 'Upload file';
        } else if (task.crmTask.modalField == 'discharge') {
          return 'Submit';
        } else if (task.crmTask.modalField == 'bank') {
          return 'Ilion';
        } else {
          return 'Review';
        }
      } else {
        if (task.status === 'open') {
          return 'Upload file';
        } else {
          return 'Review';
        }
      }
    },
  },
  async created() {
    this.isLoading = true;
    await this.fetchLoanProgress();
    await this.fetchLoanStatus();
    this.statusTask = this.selectedApplication?.applicationStatus;
    this.pendingList =
      this.tasksList?.filter((task) => task.status === 'not_yet_started' && task.assignedTo !== 'crm') || [];
    this.isLoading = false;
    this.width = window.innerWidth;
    window.addEventListener('resize', this.onResize);
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize);
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
.todo-wrapper {
  min-height: 400px;
  overflow: auto;
  width: 50%;
  margin-top: 2rem;
  position: relative;
  @media (max-width: 922px) {
    width: 100%;
  }

  .todo-completed {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    justify-content: center;

    p {
      margin: 0;
      text-align: center;
    }
  }

  .title {
    padding: 0 1rem;
    color: $primary-text-color;
  }

  .todo-list {
    margin: 0 1rem;
    margin-top: 1rem;
  }
  .list {
    border-bottom: 1px solid #bbbbbb;
    gap: 1.5rem;
    padding-bottom: 1rem;
    justify-content: space-between;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    .icon-content-wrapper {
      display: flex;
      align-items: center;
      gap: 1rem;

      .icon {
        font-size: 1.7rem;
        color: $primary-color;
      }
    }
    .content {
      .heading {
        margin: 0;
        color: $primary-text-color;
        font-weight: bold;
        font-size: 1rem;
      }

      .text {
        margin: 0;
        color: #808080;
        font-size: 0.9rem;
      }
    }
  }

  @media (max-width: 545px) {
    .mb-none {
      display: none;
    }

    .icon-content-wrapper {
      width: 100% !important;
      display: block !important;
      text-align: center;
      padding: 0;
      flex: 100%;
      border: 1px solid black;
      border-radius: 4px;
      padding: 1rem;
    }

    .text {
      margin-bottom: 0;
    }

    .list {
      justify-content: center !important;
    }
  }
}
</style>
