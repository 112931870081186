<template>
  <div class="timeline-container">
    <h3 class="title pm-txt-color bold-600">
      {{ profileDetails.firstName }} {{ profileDetails.middleName }}
      {{ profileDetails.lastName }}
      <span v-if="profileDetails.firstName">-</span> Application progress
    </h3>
    <Spinner v-if="isLoading" class="flex" />
    <div v-else class="progress-steps-wrapper">
      <div class="progress-steps p-d-flex p-ai-center">
        <div :class="renderDynamicClass(progress.STEP_1)">
          <div class="icon-wrapper">
            <!-- <div class="icon pi pi-mobile"></div> -->
            <img class="svg-icon" :src="require(`@/assets/app_sub_icon.svg`)" alt="" />
            <img class="svg-icon-active" :src="require(`@/assets/app_sub_active.svg`)" alt="" />
            <img class="svg-icon-true" :src="require(`@/assets/app_sub_true.svg`)" alt="" />
          </div>
          <div class="circle">
            <i v-if="rednerCheckIcon(progress.STEP_1)" class="pi pi-check check"></i>
          </div>
          <p class="text">Application<br />submission</p>
        </div>
        <div :class="renderDynamicClass(progress.STEP_2)">
          <div class="icon-wrapper">
            <img class="svg-icon" :src="require(`@/assets/conditonal_off_icon.svg`)" alt="" />
            <img class="svg-icon-active" :src="require(`@/assets/conditional_off_active.svg`)" alt="" />
            <img class="svg-icon-true" :src="require(`@/assets/conditonal_off_true.svg`)" alt="" />
          </div>
          <span class="circle">
            <i v-if="rednerCheckIcon(progress.STEP_2)" class="pi pi-check check"></i>
          </span>
          <p class="text">
            Conditional<br />
            offer
          </p>
        </div>
        <div :class="renderDynamicClass(progress.STEP_3)">
          <div class="icon-wrapper">
            <img class="svg-icon" :src="require(`@/assets/supporting_doc_icon.svg`)" alt="" />
            <img class="svg-icon-active" :src="require(`@/assets/supporting_doc_active.svg`)" alt="" />
            <img class="svg-icon-true" :src="require(`@/assets/supporting_doc_true.svg`)" alt="" />
          </div>
          <span class="circle">
            <i v-if="rednerCheckIcon(progress.STEP_3)" class="pi pi-check check"></i>
          </span>
          <p class="text">Supporting<br />documents</p>
        </div>
        <div :class="renderDynamicClass(progress.STEP_4)">
          <div class="icon-wrapper">
            <img class="svg-icon" :src="require(`@/assets/unconditonal_off_icon.svg`)" alt="" />
            <img class="svg-icon-active" :src="require(`@/assets/uncondtional_off_active.svg`)" alt="" />
            <img class="svg-icon-true" :src="require(`@/assets/uncondtional_off_true.svg`)" alt="" />
          </div>
          <span class="circle">
            <i v-if="rednerCheckIcon(progress.STEP_4)" class="pi pi-check check"></i>
          </span>
          <p class="text">Unconditional<br />offer</p>
        </div>
        <div :class="renderDynamicClass(progress.STEP_5)">
          <div class="icon-wrapper">
            <img class="svg-icon" :src="require(`@/assets/loan_settle_icon.svg`)" alt="" />
            <img class="svg-icon-active" :src="require(`@/assets/loan_settle_active.svg`)" alt="" />
          </div>
          <span class="circle">
            <i v-if="rednerCheckIcon(progress.STEP_5)" class="pi pi-check check"></i>
          </span>
          <p class="text">Loan<br />settlement</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  props: ['progressStatus', 'getProgress'],
  data() {
    return {
      progress: {
        STEP_1: 'active',
        STEP_2: false,
        STEP_3: false,
        STEP_4: false,
        STEP_5: false,
      },
      isLoading: true,
    };
  },
  computed: {
    ...mapGetters({
      profileDetails: 'settings/profileDetails',
      selectedApplication: 'applications/selectedApplication',
    }),
  },
  methods: {
    ...mapActions({
      fetchLoanProgress: 'applications/fetchLoanProgress',
      fetchLoanStatus: 'applications/fetchLoanStatus',
    }),
    renderDynamicClass(step) {
      if (step === 'active') {
        return 'active stepper';
      } else if (step === true) {
        return 'completed stepper';
      } else {
        return 'stepper';
      }
    },

    rednerCheckIcon(step) {
      return step === true ? true : false;
    },

    clickEvent(index, step) {
      const keys = Object.keys(this.progress);

      keys.forEach((el, idx) => {
        if (idx == index - 1) {
          this.progress[step] = 'active';
        }
        if (idx < index - 1) {
          this.progress[el] = true;
        } else if (idx > index - 1) {
          this.progress[el] = false;
        }
      });
    },
    emitToParentProgress(status) {
      this.$emit('getProgress', status);
    },
  },
  async created() {
    this.isLoading = true;
    await this.fetchLoanStatus();
    const status = this.selectedApplication.applicationStatus;
    this.$emit('getProgress', status);
    switch (status) {
      case 'created':
      case 'assessment_ready':
      case 'open':
        this.progress.STEP_1 = 'active';
        this.progress.STEP_2 = false;
        this.progress.STEP_3 = false;
        this.progress.STEP_4 = false;
        this.progress.STEP_5 = false;
        break;
      case 'declaration_pending':
        this.progress.STEP_1 = 'active';
        this.progress.STEP_2 = false;
        this.progress.STEP_3 = false;
        this.progress.STEP_4 = false;
        this.progress.STEP_5 = false;
        break;
      case 'declaration_signes':
        this.progress.STEP_1 = 'active';
        this.progress.STEP_2 = false;
        this.progress.STEP_3 = false;
        this.progress.STEP_4 = false;
        this.progress.STEP_5 = false;
        break;
      case 'decision_seeker':
        this.progress.STEP_1 = 'active';
        this.progress.STEP_2 = false;
        this.progress.STEP_3 = false;
        this.progress.STEP_4 = false;
        this.progress.STEP_5 = false;
        break;
      case 'desicion_seeker_fail':
        this.progress.STEP_1 = 'active';
        this.progress.STEP_2 = false;
        this.progress.STEP_3 = false;
        this.progress.STEP_4 = false;
        this.progress.STEP_5 = false;
        break;
      case 'desicion_seeker_warnings':
        this.progress.STEP_1 = 'active';
        this.progress.STEP_2 = false;
        this.progress.STEP_3 = false;
        this.progress.STEP_4 = false;
        this.progress.STEP_5 = false;
        break;
      case 'conditional_offer':
        this.progress.STEP_1 = true;
        this.progress.STEP_2 = 'active';
        this.progress.STEP_3 = false;
        this.progress.STEP_4 = false;
        this.progress.STEP_5 = false;
        break;
      case 'supporting_documents':
        this.progress.STEP_1 = true;
        this.progress.STEP_2 = true;
        this.progress.STEP_3 = 'active';
        this.progress.STEP_4 = false;
        this.progress.STEP_5 = false;
        break;
      case 'loan_confirmation_pending':
        this.progress.STEP_1 = true;
        this.progress.STEP_2 = true;
        this.progress.STEP_3 = 'active';
        this.progress.STEP_4 = false;
        this.progress.STEP_5 = false;
        break;
      case 'unconditional_offer':
        this.progress.STEP_1 = true;
        this.progress.STEP_2 = true;
        this.progress.STEP_3 = true;
        this.progress.STEP_4 = 'active';
        this.progress.STEP_5 = false;
        break;
      case 'legal_documents_sign_pending':
        this.progress.STEP_1 = true;
        this.progress.STEP_2 = true;
        this.progress.STEP_3 = true;
        this.progress.STEP_4 = true;
        this.progress.STEP_5 = 'active';
        break;
      case 'loan_settlement':
        this.progress.STEP_1 = true;
        this.progress.STEP_2 = true;
        this.progress.STEP_3 = true;
        this.progress.STEP_4 = true;
        this.progress.STEP_5 = 'active';
        break;
      case 'sattlement_completed':
        this.progress.STEP_1 = true;
        this.progress.STEP_2 = true;
        this.progress.STEP_3 = true;
        this.progress.STEP_4 = true;
        this.progress.STEP_5 = 'active';
        break;
      case 'repayment_complete':
        this.progress.STEP_1 = true;
        this.progress.STEP_2 = true;
        this.progress.STEP_3 = true;
        this.progress.STEP_4 = true;
        this.progress.STEP_5 = 'active';
        break;
      case 'cancelled':
        this.progress.STEP_1 = true;
        this.progress.STEP_2 = true;
        this.progress.STEP_3 = true;
        this.progress.STEP_4 = true;
        this.progress.STEP_5 = 'active';
        break;
      case 'rejected':
        this.progress.STEP_1 = true;
        this.progress.STEP_2 = true;
        this.progress.STEP_3 = true;
        this.progress.STEP_4 = true;
        this.progress.STEP_5 = 'active';
        break;
    }
    this.isLoading = false;
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

.timeline-container {
  margin-top: 2rem;
  font-family: $font-family !important;

  .title {
    color: $primary-text-color;
    padding: 0 1rem;
    font-size: 1.25rem;
  }

  .progress-steps-wrapper {
    margin: 2rem 0 0 0;
    border-bottom: 1px solid $light-text-color;
    padding-bottom: 2rem;
    box-sizing: border-box !important;
    font-weight: 600;
    .connector {
      background-color: black;
      height: 2px;
      width: 50px;
    }

    .progress-steps {
      box-sizing: border-box !important;

      padding: 0 2rem;
      width: 100%;
      gap: 3rem;
      justify-content: space-between;
      overflow: hidden;
      @media (max-width: 922px) {
        overflow: unset;
        overflow-y: scroll;
      }

      .stepper {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: center;
        justify-content: center;
        z-index: 99;
        box-sizing: border-box !important;

        .icon-wrapper {
          .icon {
            font-size: 2rem;
            color: #b1b1b1;
          }
        }

        .circle {
          cursor: pointer;
          width: 25px;
          height: 25px;
          border: 3px solid $light-text-color;
          border-radius: 50%;
          position: relative;
          background-color: $white-color;

          &::after {
            content: '';
            display: block;
            position: absolute;
            width: 50px;
            height: 10px;
            top: 0.5rem;
            left: -15px;
            background-color: white;
            z-index: -1;
          }
        }

        &:not(:last-child) {
          .circle {
            &::after {
              content: '';
              display: block;
              position: absolute;
              width: 50px;
              height: 10px;
              top: 0.5rem;
              left: -15px;
              background-color: white;
              z-index: -1;
            }

            &::before {
              content: '';
              display: block;
              position: absolute;
              width: 150px;
              height: 2px;
              left: 2rem;
              top: 0.5rem;
              background-color: #b1b1b1;
              z-index: -1;

              @media screen and (min-width: 1300px) {
                width: 250px;
              }
            }
          }
        }

        &:last-child {
          .circle {
            &::after {
              width: 250px;
              left: -15px;
              background-color: white;
              z-index: -1;
            }
          }
        }

        .text {
          white-space: wrap;
          color: $light-text-color;
          font-size: 1.2rem;
          text-align: center;
        }
      }

      .svg-icon {
        display: block;
      }

      .svg-icon-true,
      .svg-icon-active {
        display: none;
      }

      .active {
        .svg-icon-active {
          display: block;
        }

        .svg-icon {
          display: none;
        }

        .svg-icon-true {
          display: none;
        }
      }

      .completed {
        .svg-icon-active {
          display: none;
        }

        .svg-icon {
          display: none;
        }

        .svg-icon-true {
          display: block;
        }
      }

      .active .text {
        color: $primary-color !important;
        // font-weight: bold;
      }

      .active .circle {
        border: 3px solid $primary-color !important;
      }

      .completed .text {
        color: $primary-text-color !important;
        // font-weight: bold;
      }

      .completed .circle {
        border: 3px solid $primary-text-color !important;
        background-color: $primary-text-color;
        display: flex;
        align-items: center;
        justify-content: center;

        & .check {
          color: $white-color;
          font-weight: bold;
          font-size: 0.8rem;
        }
      }

      .completed .circle {
        &::before {
          background-color: $primary-text-color !important;
        }
      }
    }
  }
}
</style>
