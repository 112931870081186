<template>
  <Card class="position-relative">
    <template #title>
      <div class="align-items-center justify-content-between" :class="isMD ? '' : 'flex'">
        <img src="@/assets/applications.svg" />
        <div class="align-items-center" :class="isMD ? '' : 'flex'">
          <FilterComponent @status="getStatus" @stage="getStage" />
          <Datefilter
            class="datefilter-component"
            numberOfMonths="2"
            @filter="getDate"
            :class="isMD ? 'mt-2' : 'ml-2'"
            :key="filterKey"
            @clear="onDateFilterClear"
            :value="this.fromDate && this.toDate ? [this.fromDate, this.toDate] : ''"
          />
          <span class="p-input-icon-left" :class="isMD ? 'mt-2' : 'ml-2'">
            <i class="pi pi-search" />
            <InputText @input="getSearchString($event)" type="text" v-model="value1" placeholder="Search" />
          </span>
        </div>
      </div>
    </template>
    <template #content>
      <Spinner v-if="isLoading" class="flex" />
      <DataTable
        v-if="!isLoading && !isMD"
        v-model:selection="selected"
        :value="applicationList"
        :totalRecords="applications.length"
        data-key="loanReferenceNumber"
      >
        <Column selectionMode="multiple" headerStyle="width: 3rem" />
        <Column field="loanReferenceNumber" sortable header="ID">
          <template #body="{ data }">
            <div class="color-highlight" @click="goToProgress(data)">
              {{ data.loanReferenceNumber }}
            </div>
          </template>
        </Column>
        <Column sortable field="date" header="Date" />
        <Column sortable field="properties" header="Property details">
          <template #body="{ data }">
            <div v-for="(property, propertyIndex) in data.properties" :key="propertyIndex" class="property">
              <div v-for="(line, idx) in property" :key="idx" :class="{ 'property-type': idx === 0 }">{{ line }}</div>
            </div>
          </template>
        </Column>
        <Column sortable field="applicants" header="Customer details">
          <template #body="{ data }">
            <div v-for="(applicantName, applicantIndex) in data.applicants" :key="applicantIndex">
              {{ applicantName }}
            </div>
          </template>
        </Column>
        <Column sortable field="calculatedValue" header="Application details">
          <template #body="{ data }">Loan amount: {{ data.loanAmount }}</template>
        </Column>
        <Column class="capital" sortable field="loanStage" header="Loan stage" />
        <Column sortable field="calculatedValue" header="Brokerage">
          <template #body="{ data }">
            <div class="flex justify-content-between">
              <span>Rate:</span>
              <span>{{ data.brokerFeeRate }}</span>
            </div>
            <div class="flex justify-content-between">
              <span>Amount:</span>
              <span>{{ data.brokerFeeAmount }}</span>
            </div>
          </template>
        </Column>
      </DataTable>
      <Paginator
        v-if="!isMD"
        class="mt-2"
        :rows="5"
        :totalRecords="applicationsCount"
        :rowsPerPageOptions="[5, 10, 20, 30]"
        template="RowsPerPageDropdown  CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
        currentPageReportTemplate="Showing {first} of {totalRecords} applications"
        @page="onChangePage($event)"
      />
      <div v-if="!isLoading && isMD">
        <div class="application-card-container" v-for="(item, i) in applicationList" :key="i">
          <div class="flex align-items-center col col-12 gap-4">
            <Checkbox v-model="selected" :value="item" />
            <div class="color-highlight" @click="goToProgress(data)">
              {{ item.loanReferenceNumber }}
            </div>
          </div>
          <div class="full-width">
            <div class="flex full-width">
              <div class="col col-6">
                <div class="title">Date</div>
                <div>{{ item.date }}</div>
              </div>
              <div class="col col-6 capital">
                <div class="title">Loan stage</div>
                <div>{{ item.loanStage }}</div>
              </div>
            </div>
            <div class="flex flex-wrap full-width">
              <div class="col col-12">
                <div class="title">Customer details</div>
                <div v-for="(applicantName, applicantIndex) in item.applicants" :key="applicantIndex">
                  {{ applicantName }}
                </div>
              </div>
              <div class="col col-12">
                <div class="title mb-1">Property details</div>
                <div v-for="(property, propertyIndex) in item.properties" :key="propertyIndex" class="property">
                  <div v-for="(line, idx) in property" :key="idx" :class="{ 'property-type': idx === 0 }">
                    {{ line }}
                  </div>
                </div>
              </div>
              <div class="col col-12">
                <div class="title">Application details</div>
                <div class="flex justify-content-between line-detail">
                  <span>Loan amount:</span>
                  <span>{{ item.loanAmount }}</span>
                </div>
              </div>
              <div class="col col-12">
                <div class="title">Brokerage</div>
                <div class="flex justify-content-between line-detail">
                  <span>Rate:</span>
                  <span>{{ item.brokerFeeRate }}</span>
                </div>
                <div class="flex justify-content-between line-detail">
                  <span>Amount:</span>
                  <span>{{ item.brokerFeeAmount }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
import { API, TECHLEND_BE_URL } from '@/constants.api';
import { _get } from '@/services/crudService';
import { debounce } from 'debounce';
import { formatCurrency, formatDate, formatNumber, fromSnakeCase } from '@/services/formatHelper';
import { mapActions } from 'vuex';
import Card from 'primevue/card';
import Checkbox from 'primevue/checkbox';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import Datefilter from './Datefilter.vue';
import FilterComponent from './Filter.vue';
import InputText from 'primevue/inputtext';
import Paginator from 'primevue/paginator';
import ScreenSize from '@/mixins/screensize.mixin';

export default {
  name: 'index',
  mixins: [ScreenSize],
  components: {
    DataTable,
    Column,
    Card,
    InputText,
    Datefilter,
    FilterComponent,
    Checkbox,
    Paginator,
  },
  setup() {
    return {
      formatCurrency,
      formatDate,
      formatNumber,
      fromSnakeCase,
    };
  },
  data() {
    return {
      applications: [],
      isLoading: true,
      fromDate: '',
      toDate: '',
      searchString: '',
      statusList: [],
      stageList: [],
      applicationsCount: 0,
      selected: [],
    };
  },
  computed: {
    applicationList() {
      const formatAddress = (type) => (property) => {
        const line1 = [property.line1, property.line2].filter(Boolean).join(', ');
        const line2 = [property.city, property.state?.toUpperCase()].filter(Boolean).join(', ');

        return [type, line1, line2, property.postCode].filter(Boolean);
      };
      const formatName = (applicant) => [applicant.firstName, applicant.lastName].filter(Boolean).join(' ');
      return this.applications.map((app) => {
        const loanAmount = app.calculatedValue.loanAmount ?? 0;
        const brokerFeeRate = app.calculatedValue.brokerFee ?? 0;

        return {
          id: app.id,
          loanReferenceNumber: app.loanReferenceNumber,
          date: formatDate(app.createdAt),
          properties: [
            ...app.incomingProperties.map(formatAddress('Incoming')),
            ...app.outgoingProperties.map(formatAddress('Outgoing')),
          ],
          applicants: app.applicants.map(formatName),
          loanStage: fromSnakeCase(app.applicationStatus),
          loanAmount: formatCurrency(loanAmount),
          brokerFeeRate: formatNumber(brokerFeeRate * 100) + '%',
          brokerFeeAmount: formatCurrency(loanAmount * brokerFeeRate),
          application: app,
        };
      });
    },
  },
  methods: {
    ...mapActions({
      setApplicationId: 'applications/setApplicationId',
      setLoanReferenceNumber: 'applications/setLoanReferenceNumber',
    }),
    async getStatus(value) {
      this.statusList = [];
      if (value.open) this.statusList.push('open');
      if (value.repaid) this.statusList.push('repaid');
      if (value.droppedOff) this.statusList.push('droppedOff');
      if (value.withdrawn) this.statusList.push('withdrawn');
      if (value.rejected) this.statusList.push('rejected');
      await this.doFilter();
    },
    async getStage(value) {
      this.stageList = [];
      if (value.created) this.stageList.push('created');
      if (value.conditional_offer) this.stageList.push('conditional_offer');
      if (value.supporting_documents) this.stageList.push('supporting_documents');
      if (value.unconditional_offer) this.stageList.push('unconditional_offer');
      if (value.loan_settlement) this.stageList.push('loan_settlement');
      await this.doFilter();
    },
    async goToProgress(data) {
      await this.setApplicationId(data.id);
      await this.setLoanReferenceNumber(data.loanReferenceNumber);
      if (
        data.status == 'cancelled' ||
        data.loan_status === 'dropped_off' ||
        data.loan_status === 'withdrawn' ||
        data.loan_status === 'rejected'
      ) {
        this.$router.push(`/application/summary/${data.loanReferenceNumber}?progress=false`);
      } else {
        this.$router.push(`/application/progress/${data.loanReferenceNumber}?progress=true`);
      }
    },
    async doFilter() {
      this.isLoading = true;
      // if (
      //   this.searchString ||
      //   this.fromDate ||
      //   this.toDate ||
      //   this.statusList.length ||
      //   this.stageList.length
      // ) {
      //   const result = await _post(SERVER_URL_6 + "search-data", {
      //     name: this.searchString,
      //     type: "broker",
      //     startDate: this.fromDate,
      //     endDate: this.toDate,
      //     status: this.stageList,
      //     stage: this.statusList,
      //   });

      //   this.applications = result?.data ? result?.data : [];
      //   this.applications.map((obj) => {
      //     obj.customer = obj.applicants.map((item) => {
      //       if (item.applicantType == "primary") {
      //         return item.firstName;
      //       }
      //     });
      //     return obj;
      //   });
      // } else {
      const result = await _get(
        TECHLEND_BE_URL +
          API.APPLICATION.APPLICATION +
          '?offset=0&limit=5&fromDate=' +
          this.fromDate +
          '&toDate=' +
          this.toDate +
          '&applicationStatuses=' +
          this.statusList +
          '&crmStatuses=' +
          this.stageList,
      );
      this.applications = result?.data ? result?.data?.applications : [];
      this.applications.map((obj) => {
        obj.customer = obj.applicants.map((item) => {
          if (item.applicantType == 'primary') {
            return item.firstName;
          }
        });
        return obj;
      });
      // }
      this.isLoading = false;
    },
    getSearchString: debounce(async function ({ target }) {
      this.searchString = target.value;
      await this.doFilter();
    }, 800),
    async onDateFilterClear() {
      this.fromDate = '';
      this.toDate = '';
      this.filterKey += 1;
      await this.doFilter();
    },
    async getDate(value) {
      this.fromDate = new Date(value[0]);
      this.toDate = new Date(value[1]);
      this.filterKey += 1;
      await this.doFilter();
    },
    async onChangePage(value) {
      let page = value.page + 1;
      this.isLoading = true;
      const result = await _get(
        TECHLEND_BE_URL +
          API.APPLICATIONS.LIST_APPLICATIONS +
          '?offset=' +
          Number(page) * value.rows +
          '&limit=' +
          value.rows,
      );
      this.applications = result?.data?.applications ? result?.data?.applications : [];
      this.applicationsCount = result?.data?.count ? Number(result?.data?.count) : 0;
      this.applications.map((obj) => {
        obj.customer = obj.applicants.map((item) => {
          if (item.applicantType == 'primary') {
            return item.firstName;
          }
        });
        return obj;
      });
      this.isLoading = false;
    },
  },
  watch: {
    selected(items) {
      this.$emit(
        'list',
        items.map((item) => item.application),
      );
    },
  },
  async created() {
    this.isLoading = true;
    try {
      const result = await _get(TECHLEND_BE_URL + API.APPLICATIONS.LIST_APPLICATIONS + '?offset=0&limit=5');
      this.applications = result?.data?.applications ? result?.data?.applications : [];
      this.applicationsCount = result?.data?.count ? Number(result?.data?.count) : 0;
      this.applications.map((obj) => {
        obj.customer = obj.applicants.map((item) => {
          if (item.applicantType == 'primary') {
            return item.firstName;
          }
        });
        return obj;
      });
    } finally {
      this.isLoading = false;
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

.property + .property {
  margin-top: 1rem;
}
.property-type {
  color: rgba(2, 0, 56, 0.3);
  font-size: 0.75rem;
  text-transform: uppercase;
}

.line-detail {
  max-width: 300px;
}

:deep(.p-card-body) {
  overflow: auto !important;
}
.p-card {
  box-shadow: unset !important;
  border-radius: 4px !important;
  color: $primary-text-color !important;
  font-family: $font-family !important;
}

:deep(.p-card-title) {
  font-size: 1.25rem !important;
}
:deep(.p-datatable .p-datatable-thead > tr > th) {
  background: $white-color;
  color: rgba(2, 0, 56, 0.3) !important;
  font-size: 1rem;
}
:deep(.p-datatable .p-datatable-tbody > tr > td) {
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #020038 !important;
  font-family: HK-medium !important;
  vertical-align: top !important;
}
.color-highlight {
  color: $primary-color !important;
  cursor: pointer;
}
:deep(.p-paginator .p-paginator-pages .p-paginator-page.p-highlight) {
  background: unset !important;
  color: $primary-color !important;
  border-color: unset !important;
}
:deep(.p-link:focus) {
  box-shadow: unset !important;
}
:deep(.p-datepicker table td) {
  padding: unset !important;
}
:deep(.p-paginator .p-paginator-current) {
  color: rgba(2, 0, 56, 0.3);
  font-weight: 500;
  font-size: 14px;
}
.datefilter-component {
  width: 240px;
}
:deep(.p-inputtext) {
  font-family: HK-medium !important;
}
.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.1rem #ced4da !important;
  border-color: #ced4da !important;
}
:deep(.p-column-title) {
  white-space: nowrap !important;
}

:deep(.p-checkbox .p-checkbox-box.p-highlight) {
  background: $primary-color;
  border-color: $primary-color;

  &:hover {
    background: darken($primary-color, 15%) !important;
    border-color: darken($primary-color, 15%) !important;
  }
}
:deep(.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus) {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.1rem #ced4da;
  border-color: #ced4da !important;
}
:deep(.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover) {
  border-color: #ced4da !important;
}
.application-card-container {
  font-weight: 500;
  font-size: 14px;
  border-bottom: 1px solid rgba(2, 0, 56, 0.15);
  .title {
    color: rgba(2, 0, 56, 0.4);
  }
}
</style>
