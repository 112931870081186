<template>
  <Card class="login-card">
    <template #title> <p class="header-text">Log in to your account</p></template>
    <template #content>
      <div class="mb-3">
        <div class="mb-1 label">Enter your email address</div>
        <div class="position-relative">
          <TextField
            type="email"
            class="email-field"
            placeholder="Email address"
            width="100%"
            :value="formData.to"
            @onInput="getFormData($event, 'to')"
            @keypress="noWhiteSpaceKeys"
          />
          <div v-if="!isAdmin" @click="sendOTP" class="position-absolute send-code cursor-pointer">Send code</div>
        </div>
      </div>

      <div class="mb-1 label">Enter the <span v-if="!isAdmin"> 6 digit</span> verification code</div>
      <TextField
        type="number"
        placeholder="Verification code"
        width="100%"
        maxLength="6"
        :value="formData.otp"
        @onInput="getFormData($event, 'otp')"
        @keyup.enter="signin()"
      />
      <div>
        <Button class="mt-5" label="Sign in" width="100%" type="primary" @click="signin()" />
      </div>
      <div class="apply-now flex justify-content-center mt-3">
        <a href="https://bridgit.com.au/accreditation-sign-up" class="task-hover"> Partner With Us </a>
        <!-- <div @click="applyNow" class="cursor-pointer task-hover">
          Partner With Us
        </div> -->
      </div>
    </template>
  </Card>
</template>

<script>
import { AUTH_TYPES } from '@/constants';
import { mapActions } from 'vuex';
import Card from 'primevue/card';

export default {
  name: 'Login',
  components: {
    Card,
  },

  data() {
    return {
      formData: {
        to: '',
        otp: '',
      },
      isOtpReceived: false,
      isAdmin: false,
    };
  },
  methods: {
    ...mapActions(['setLogin', 'fetchOtp', 'verifyOTP']),
    applyNow() {
      this.$router.push('/signup/contract');
    },
    async sendOTP() {
      try {
        const payload = {
          email: this.formData.to,
          authType: AUTH_TYPES.LOGIN,
        };
        await this.fetchOtp(payload);
        await this.$toast.add({
          severity: 'success',
          summary: 'Success',
          detail: 'OTP sent successfully',
          life: 3000,
        });
        this.isOtpReceived = true;
      } catch (err) {
        if (err.response && err.response.data && err.response.data.message) {
          await this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: err.response.data.message,
            life: 3000,
          });
        } else {
          await this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Something went wrong',
            life: 3000,
          });
        }
        this.isOtpReceived = false;
      }
    },
    async signin() {
      if (!this.formData.to) {
        await this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Email address is missing',
          life: 3000,
        });
        return;
      }
      if (!this.formData.otp) {
        await this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Verification code is missing',
          life: 3000,
        });
        return;
      }
      try {
        await this.verifyOTP({
          email: this.formData.to.toLowerCase(),
          otp: this.formData.otp,
          authType: AUTH_TYPES.LOGIN,
        });
        // await this.$toast.add({
        //   severity: "success",
        //   summary: "Success",
        //   detail: "Logged in successfully",
        //   life: 3000,
        // });
        this.$router.push('/dashboard');
      } catch (err) {
        if (err.response && err.response.data && err.response.data.message) {
          await this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: err.response.data.message,
            life: 3000,
          });
        } else {
          await this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Invalid login details',
            life: 3000,
          });
        }
      }
    },
    isSubmitDisabled() {
      return this.v$.$invalid;
    },
    getFormData(value, fieldName) {
      this.formData[fieldName] = value;
      this.formData = { ...this.formData };
    },
    noWhiteSpaceKeys(event) {
      if (event.key.match(/\s/)) {
        event.preventDefault();
      }
    },
  },
  watch: {
    $route() {
      this.isAdmin = this.$route.query.admin ? this.$route.query.admin : false;
      this.isOtpReceived = this.isAdmin;
    },
  },
  created() {
    this.isAdmin = this.$route.query.admin && this.$route.query.admin == 'true' ? true : false;
    this.isOtpReceived = this.isAdmin;
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
.p-card {
  color: $primary-text-color !important;
  font-family: $font-family !important;
  height: 100% !important;
  width: 408px;
}

.label {
  font-family: HK-medium;
  font-size: 16px;
}

.header-text {
  font-size: 28px;
  margin: 0;
  font-family: Gordita-bold;
}

.email-field :deep(.p-inputtext) {
  padding-right: 5.5rem !important;
}

:deep(.p-card-body) {
  padding: 2.5rem 2.5rem 2rem 2.5rem !important;
}

:deep(.p-float-label) {
  margin: 0 !important;
}

:deep(.p-card-title) {
  color: #020038 !important;
  font-size: 32px !important;
}
:deep(.p-card-content) {
  font-size: 14px !important;
  color: #020038 !important;
  font-weight: 500;
  font-size: 14px;
  padding: 0 !important;
  margin-top: 24px;
}
.apply-now {
  color: rgba(2, 0, 56, 0.4) !important;
  font-weight: bold;
  font-size: 14px;
  width: 100%;
  text-align: center;
}
.send-code {
  top: 10px;
  right: 10px;
  color: #54b7f9;
  font-weight: 600;
  font-size: 1rem;
}
// :deep(.p-card-body) {
//   padding-top: 1.5rem !important;
//   padding-right: 2rem !important;
//   padding-bottom: 1.5rem !important;
//   padding-left: 1rem !important;
// }
.task-hover {
  text-decoration: none;
  color: rgba(2, 0, 56, 0.4);
}
.task-hover:hover {
  color: $primary-color;
}
</style>
