<template>
  <div>
    <h1 class="module-title">Mobile number verification</h1>
    <div class="position-relative" :class="isMD ? '' : 'w-410'">
      <TextField
        :width="isMD ? '100%' : '410px'"
        label="Enter your mobile number"
        @onInput="getFormData($event, 'mobile')"
        :value="formData.mobile"
        type="number"
        placeholder="Your mobile number"
      />
      <div :class="{ 'disable-otp': otpDisable }" class="position-absolute send-code cursor-pointer" @click="sendOTP">
        <span v-if="!isReferralSignupResend"> Send code</span>
        <span v-else> Resend code</span>
      </div>
    </div>
    <TextField
      width="410px"
      class="mt-3"
      label="Enter the 6 digit verification code"
      @onInput="getFormData($event, 'otp')"
      :value="formData.otp"
      type="number"
      placeholder="Verification code"
    />
    <div @click="goToNext" class="flex align-items-center">
      <Button class="mt-3" label="Submit" type="primary" :disabled="isSubmitDisabled()" />
      <div class="back mt-3 ml-4 cursor-pointer" @click="goBack">Back</div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { maxLength, required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
// import { checkMobileNumber } from "../../../services/validatePhNo";
import { AUTH_TYPES } from '@/constants';
import Screensize from '@/mixins/screensize.mixin';

export default {
  name: 'Licensing',
  components: {},
  mixins: [Screensize],
  data() {
    return {
      formData: {
        mobile: '',
        otp: '',
      },
      otp: '123456',
      otpDisable: false,
    };
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      formData: {
        otp: { required, maxLength: maxLength(6) },
        mobile: {
          required,
          // checkMobileNumbers: (mobile) => {
          //   const result = checkMobileNumber(mobile);
          //   return result.isValid;
          // },
        },
      },
    };
  },
  methods: {
    ...mapActions([
      'referralUpdateSubProgressList',
      'referralUpdateApplicantProgress',
      'referralUpdateSelectedSubProgressIndex',
      'referralUpdateSelectedSubProgressIndex',
      'referralUpdateSelectedApplicantPrgressIndex',
      'referralUpdateIsActiveValue',
      'setLogin',
    ]),
    ...mapActions({
      setReferralFormData: 'brokerSignup/setReferralFormData',
      registerBroker: 'brokerSignup/registerBroker',
      verifyOtp: 'brokerSignup/verifyOtp',
      createReferralBroker: 'brokerSignup/createReferralBroker',
      setIsReferralSignupResend: 'brokerSignup/setIsReferralSignupResend',
    }),
    isSubmitDisabled() {
      return this.v$.$invalid;
    },
    async sendOTP() {
      if (this.formData.mobile) {
        this.otpDisable = true;
        let payload = {
          mobilePhoneNumber: this.formData.mobile,
          authType: AUTH_TYPES.SIGNUP,
        };
        try {
          if (!this.isReferralSignupResend) {
            await this.registerBroker(payload);
          } else {
            await this.registerBroker(payload);
          }
          this.setIsReferralSignupResend(true);
          await this.$toast.add({
            severity: 'success',
            detail: 'OTP sent successfully',
            life: 3000,
          });
          this.otpDisable = false;
        } catch (err) {
          if (err.response && err.response.data && err.response.data.message) {
            await this.$toast.add({
              severity: 'error',
              summary: 'Error',
              detail: err.response.data.message,
              life: 3000,
            });
          } else {
            await this.$toast.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Something went wrong',
              life: 3000,
            });
          }
          this.otpDisable = false;
        }
      }
    },
    getFormData(value, fieldName) {
      this.formData[fieldName] = value;
      this.formData = { ...this.formData };
      this.setIsReferralSignupResend(false);
    },
    goBack() {
      let currentObj = this.referralSubProgressList[4];
      currentObj[0].isActive = false;
      currentObj[0].color = 'rgba(255, 255, 255, 0.3)';
      this.referralUpdateSubProgressList(currentObj);
      this.referralUpdateSelectedSubProgressIndex(3);
      let prevObj = this.referralSubProgressList[3];
      prevObj[0].isActive = true;
      prevObj[0].color = '#54B7F9';
      prevObj[0].isCompleted = false;
      this.referralUpdateSubProgressList(prevObj);
      this.referralUpdateApplicantProgress(64);
      this.referralUpdateSelectedApplicantPrgressIndex(3);

      this.$router.push('/referral-signup/payment');
    },
    async goToNext() {
      if (!this.v$.$invalid) {
        // this.setLogin(false);
        try {
          await this.verifyOtp({
            otp: this.otp ? Number(this.otp) : 0,
            mobile: this.formData.mobile,
          });
          await this.$toast.add({
            severity: 'success',
            detail: 'OTP verified successfully',
            life: 3000,
          });
          let currentObj = this.referralSubProgressList[this.referralSelectedSubProgressIndex];
          currentObj[0].isActive = false;
          currentObj[0].isCompleted = true;
          currentObj[0].color = '#54B7F9';
          this.referralUpdateSubProgressList(currentObj);
          this.referralUpdateApplicantProgress(100);
          this.referralUpdateSelectedSubProgressIndex(5);
          this.referralUpdateSelectedApplicantPrgressIndex(5);
          this.referralUpdateIsActiveValue({ index1: 5, index2: 0 });
          this.setReferralFormData(this.formData);
          this.$router.push('/referral-signup/declaration');
        } catch (err) {
          if (err.response && err.response.data && err.response.data.message) {
            await this.$toast.add({
              severity: 'error',
              summary: 'Error',
              detail: err.response.data.message,
              life: 3000,
            });
          } else {
            await this.$toast.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Something went wrong',
              life: 3000,
            });
          }
        }
      }
    },
  },
  computed: {
    ...mapGetters(['referralSelectedSubProgressIndex', 'referralSubProgressList']),
    ...mapGetters('brokerSignup', ['referralFormData', 'isReferralSignupResend']),
  },
  created() {
    this.formData.mobile = this.referralFormData.mobile ? this.referralFormData.mobile : '';
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
.title-text {
  max-width: 300px;
  color: #020038;
  font-size: 16px;
}
.col {
  padding: unset;
}
.back {
  color: rgba(2, 0, 56, 0.4);
  font-weight: bold;
  font-size: 18px;
}
.send-code {
  top: 3.2rem;
  right: 10px;
  color: #54b7f9;
  font-weight: 600;
  font-size: 1rem;
}
.w-410 {
  width: 410px;
}
</style>
