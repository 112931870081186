<template>
  <div>
    <h1 class="module-title">Payment details</h1>
    <div class="title-text mt-3 bold-600">
      Please enter the details of the account you would like to receive payment.
    </div>
    <TextField
      width="100%"
      label="Account name"
      class="mt-3"
      @onInput="getFormData($event, 'accountName')"
      :value="formData.accountName"
      placeholder="Your name as registered on the account"
    />
    <div :class="isMD ? '' : 'flex'">
      <div class="col" :class="isMD ? '' : 'col-6'">
        <TextField
          width="100%"
          label="BSB"
          class="mt-3"
          @onInput="getFormData($event, 'bsb')"
          :value="formData.bsb"
          placeholder="Your BSB code"
        />
      </div>
      <div class="col" :class="isMD ? '' : 'col-6 ml-4'">
        <TextField
          width="100%"
          class="mt-3"
          label="Account number"
          @onInput="getFormData($event, 'accNo')"
          :value="formData.accNo"
          placeholder="Your account number"
        />
      </div>
    </div>

    <div class="flex align-items-center">
      <div>
        <Button
          class="mt-4"
          icon="pi pi-chevron-right"
          iconPos="right"
          label="Next"
          type="primary"
          :disabled="isSubmitDisabled()"
          @click="goToNext"
        />
      </div>
      <div class="back mt-3 ml-4 cursor-pointer" @click="goBack">Back</div>
    </div>
  </div>
</template>

<script>
import { integer, maxLength, minLength, required } from '@vuelidate/validators';
import { mapActions, mapGetters } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import Screensize from '@/mixins/screensize.mixin';

export default {
  name: 'Payment',
  mixins: [Screensize],
  components: {},
  data() {
    return {
      formData: {
        bsb: '',
        accNo: '',
        accountName: '',
      },
    };
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      formData: {
        bsb: {
          required,
          integer,
          minLength: minLength(6),
          maxLength: maxLength(6),
        },
        accNo: { required, integer },
        accountName: { required },
      },
    };
  },
  methods: {
    ...mapActions([
      'referralUpdateSubProgressList',
      'referralUpdateApplicantProgress',
      'referralUpdateSelectedSubProgressIndex',
      'referralUpdateSelectedSubProgressIndex',
      'referralUpdateSelectedApplicantPrgressIndex',
      'referralUpdateIsActiveValue',
    ]),
    ...mapActions({
      setReferralFormData: 'brokerSignup/setReferralFormData',
    }),
    isSubmitDisabled() {
      return this.v$.$invalid;
    },
    getFormData(value, fieldName) {
      this.formData[fieldName] = value;
      this.formData = { ...this.formData };
    },
    goBack() {
      let currentObj = this.referralSubProgressList[3];
      currentObj[0].isActive = false;
      currentObj[0].color = 'rgba(255, 255, 255, 0.3)';
      this.referralUpdateSubProgressList(currentObj);
      this.referralUpdateSelectedSubProgressIndex(2);
      let prevObj = this.referralSubProgressList[2];
      prevObj[0].isActive = true;
      prevObj[0].color = '#54B7F9';
      prevObj[0].isCompleted = false;
      this.referralUpdateSubProgressList(prevObj);
      this.referralUpdateApplicantProgress(44);
      this.referralUpdateSelectedApplicantPrgressIndex(2);
      this.$router.push('/referral-signup/company-address');
    },
    goToNext() {
      let currentObj = this.referralSubProgressList[this.referralSelectedSubProgressIndex];
      currentObj[0].isActive = false;
      currentObj[0].isCompleted = true;
      currentObj[0].color = '#54B7F9';
      this.referralUpdateSubProgressList(currentObj);
      this.referralUpdateApplicantProgress(82);
      this.referralUpdateSelectedSubProgressIndex(4);
      this.referralUpdateSelectedApplicantPrgressIndex(4);
      this.referralUpdateIsActiveValue({ index1: 4, index2: 0 });
      this.setReferralFormData(this.formData);
      this.$router.push('/referral-signup/verification');
    },
  },
  computed: {
    ...mapGetters(['referralSubProgressList', 'referralSelectedSubProgressIndex']),
    ...mapGetters('brokerSignup', ['referralFormData']),
  },
  created() {
    this.formData.bsb = this.referralFormData.bsb ? this.referralFormData.bsb : '';
    this.formData.accNo = this.referralFormData.accNo ? this.referralFormData.accNo : '';
    this.formData.accountName = this.referralFormData.accountName ? this.referralFormData.accountName : '';
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
.title-text {
  color: #020038;
  font-size: 16px;
}
.col {
  padding: unset;
}
.back {
  color: rgba(2, 0, 56, 0.4);
  font-weight: bold;
  font-size: 18px;
}
</style>
