import { API, TECHLEND_BE_URL } from '@/constants.api';
import { _get } from '@/services/crudService';

export default {
  namespaced: true,

  state: {
    applications: [],
    applicationId: '',
    selectedTaskId: '',
    selectedTask: {},
    selectedApplication: {},
    isSupporting: false,
    propId: '',
    newLoanReferenceNumber: '',
    solicitorDetail: {},
  },
  getters: {
    applications: ({ applications }) => applications,
    selectedApplicationId: ({ applicationId }) => applicationId,
    selectedTaskId: ({ selectedTaskId }) => selectedTaskId,
    selectedApplication: ({ selectedApplication }) => selectedApplication,
    isSupporting: ({ isSupporting }) => isSupporting,
    propId: ({ propId }) => propId,
    newLoanReferenceNumber: ({ newLoanReferenceNumber }) => newLoanReferenceNumber,
    solicitorDetail: ({ solicitorDetail }) => solicitorDetail,
    tasksList: ({ tasksList }) => tasksList,
  },
  mutations: {
    setApplications(state, payload) {
      state.applications = payload;
    },
    setApplicationId(state, payload) {
      state.applicationId = payload;
    },
    setLoanProgress(state, payload) {
      state.tasksList = payload;
    },
    setSelectedTask(state, payload) {
      state.selectedTask = payload;
    },
    setSelectedTaskId(state, payload) {
      state.selectedTaskId = payload;
    },
    setSelectedLoanDetails(state, payload) {
      state.selectedApplication = payload;
    },
    setIsSupporting(state, payload) {
      state.isSupporting = payload;
    },
    setCrmTaskId(state, payload) {
      state.propId = payload;
    },
    setLoanReferenceNumber(state, payload) {
      state.newLoanReferenceNumber = payload;
    },
    setSolicitorDetails(state, payload) {
      state.solicitorDetail = payload;
    },
  },
  actions: {
    async fetchApplications({ commit }) {
      const result = await _get(TECHLEND_BE_URL + API.APPLICATIONS.LIST_APPLICATIONS);
      commit('setApplications', result.data.applications);
    },
    async fetchLoanProgress({ commit, state }) {
      const taskList = await _get(TECHLEND_BE_URL + API.TASKS.LIST_TASKS + `?applicationId=${state.applicationId}`);
      commit('setLoanProgress', taskList.data.tasks);
      return taskList.data.tasks;
    },
    async fetchLoanStatus({ commit, state }) {
      const result = await _get(TECHLEND_BE_URL + API.APPLICATIONS.RETRIEVE_APPLICATION + `?id=${state.applicationId}`);
      commit('setSelectedLoanDetails', result.data.application || {});
    },
    setApplicationId({ commit }, id) {
      commit('setApplicationId', id);
    },
    setLoanProgress({ commit }, tasks) {
      commit('setLoanProgress', tasks);
    },
    setSelectedTaskId({ commit }, id) {
      commit('setSelectedTaskId', id);
    },
    setSelectedTask({ commit }, task) {
      commit('setSelectedTask', task);
    },
    setIsSupporting({ commit }, value) {
      commit('setIsSupporting', value);
    },
    async fetchLoanSummary({ state, commit }) {
      const result = await _get(TECHLEND_BE_URL + API.APPLICATIONS.RETRIEVE_APPLICATION + `?id=${state.applicationId}`);
      if (result.data && result.data.application) {
        commit('setSelectedLoanDetails', result.data.application);
      } else {
        commit('setSelectedLoanDetails', {});
      }
    },
    setCrmTaskId({ commit }, id) {
      commit('setCrmTaskId', id);
    },
    setLoanReferenceNumber({ commit }, loanReferenceNumber) {
      commit('setLoanReferenceNumber', loanReferenceNumber);
    },
    async getSolictorDetailsById({ commit, state }) {
      console.state;
      try {
        const { data } = await _get(
          TECHLEND_BE_URL + API.SOLICITORS.SOLICITOR + `?applicationId=${state.applicationId}`,
        );
        if (data) {
          commit('setSolicitorDetails', data);
        }
        return data;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
