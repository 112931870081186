<template>
  <div>
    <h1 class="module-title">Name</h1>
    <div class="title-text bold-600 mt-3">
      Please enter your full legal name as it appears on your driver's license.
    </div>
    <TextField
      class="mt-3"
      width="100%"
      label="First name"
      @onInput="getFormData($event, 'firstName')"
      :value="formData.firstName"
      placeholder="First name"
    />
    <TextField
      class="mt-3"
      label="Middle name"
      width="100%"
      @onInput="getFormData($event, 'middleName')"
      :value="formData.middleName"
      placeholder="Middle name"
    />
    <TextField
      width="100%"
      class="mt-3"
      label="Last name"
      @onInput="getFormData($event, 'lastName')"
      :value="formData.lastName"
      placeholder="Last name"
    />
    <div @click="goNext">
      <Button
        class="mt-3"
        icon="pi pi-chevron-right"
        iconPos="right"
        label="Next"
        type="primary"
        :disabled="isSubmitDisabled()"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';

export default {
  name: 'Name',
  components: {},
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      formData: {
        firstName: { required },
        lastName: { required },
      },
    };
  },
  data() {
    return {
      formData: {
        firstName: '',
        lastName: '',
        middleName: '',
      },
    };
  },
  methods: {
    ...mapActions(['referralUpdateSubProgressList', 'referralUpdateSelectedSubProgressIndex']),
    ...mapActions({
      setReferralFormData: 'brokerSignup/setReferralFormData',
    }),
    isSubmitDisabled() {
      return this.v$.$invalid;
    },
    getFormData(value, fieldName) {
      this.formData[fieldName] = value;
      this.formData = { ...this.formData };
    },
    goNext() {
      if (!this.v$.$invalid) {
        let currentObj = this.referralSubProgressList[this.referralSelectedSubProgressIndex];
        currentObj[0].isCompleted = true;
        currentObj[0].isActive = false;
        currentObj[0].color = 'rgba(255, 255, 255, 0.3)';
        currentObj[1].isActive = true;
        currentObj[1].color = '#54B7F9';
        this.referralUpdateSubProgressList(currentObj);
        this.setReferralFormData(this.formData);
        this.$router.push('/referral-signup/address');
      }
    },
  },
  computed: {
    ...mapGetters(['referralSubProgressList', 'referralSelectedSubProgressIndex']),
    ...mapGetters('brokerSignup', ['referralFormData']),
  },
  created() {
    this.formData.firstName = this.referralFormData.firstName ? this.referralFormData.firstName : '';
    this.formData.middleName = this.referralFormData.middleName ? this.referralFormData.middleName : '';
    this.formData.lastName = this.referralFormData.lastName ? this.referralFormData.lastName : '';
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
.title-text {
  max-width: 350px;
  color: #020038;
  font-size: 16px;
}
</style>
