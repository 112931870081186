<template>
  <div>
    <h1 class="module-title">Mobile number verification</h1>
    <div class="position-relative" :class="isMD ? '' : 'w-410'">
      <TextField
        :width="isMD ? '100%' : '410px'"
        label="Enter your mobile number"
        @onInput="getFormData($event, 'mobile')"
        maxlength="11"
        type="number"
        :value="formData.mobile"
        placeholder="Your mobile number"
      />
      <div class="position-absolute send-code cursor-pointer" @click="sendOTP">
        <span v-if="!isSignupResend"> Send code</span>
        <span v-else> Resend code</span>
      </div>
    </div>
    <TextField
      width="410px"
      class="mt-3"
      label="Enter the 6 digit verification code"
      @onInput="getFormData($event, 'otp')"
      maxlength="6"
      type="number"
      :value="formData.otp"
      placeholder="Verification code"
    />
    <div @click="goToNext" class="flex align-items-center">
      <Button class="mt-3" label="Submit" type="primary" :disabled="isSubmitDisabled() || isLoading" />
      <div class="back mt-3 ml-4 cursor-pointer" @click="goBack">Back</div>
    </div>
  </div>
</template>

<script>
import { API, TECHLEND_BE_URL } from '@/constants.api';
import { _post } from '@/services/crudService';
import { integer, maxLength, minLength, required } from '@vuelidate/validators';
import { mapActions, mapGetters } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import Screensize from '@/mixins/screensize.mixin';

export default {
  name: 'Licensing',
  components: {},
  mixins: [Screensize],
  data() {
    return {
      isLoading: false,
      formData: {
        mobile: '',
        otp: '',
      },
    };
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      formData: {
        otp: { required, maxLength: maxLength(6) },
        mobile: {
          required,
          integer,
          maxLength: maxLength(10),
          minLength: minLength(10),
        },
      },
    };
  },
  methods: {
    ...mapActions([
      'updateSubProgressList',
      'updateApplicantProgress',
      'updateSelectedSubProgressIndex',
      'updateSelectedSubProgressIndex',
      'updateSelectedApplicantPrgressIndex',
      'setLogin',
      'updateIsActiveValue',
    ]),
    ...mapActions({
      setFormData: 'brokerSignup/setFormData',
      createBroker: 'brokerSignup/createBroker',
      resendOtp: 'brokerSignup/resendOtp',
      registerBroker: 'brokerSignup/registerBroker',
      verifyOtp: 'brokerSignup/verifyOtp',
      setIsSignupResend: 'brokerSignup/setIsSignupResend',
    }),
    async sendOTP() {
      try {
        const payload = {
          mobilePhoneNumber: this.formData.mobile,
        };
        await this.resendOtp(payload);
        this.$toast.add({
          severity: 'success',
          summary: 'OTP sent successfully',
          life: 2000,
        });
      } catch (err) {
        if (err.response && err.response.data && err.response.data.message) {
          await this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: err.response.data.message,
            life: 3000,
          });
        } else {
          await this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Something went wrong',
            life: 3000,
          });
        }
      }
    },
    isSubmitDisabled() {
      return this.v$.$invalid;
    },
    getFormData(value, fieldName) {
      this.formData[fieldName] = value;
      this.formData = { ...this.formData };
      this.setIsSignupResend(false);
    },
    goBack() {
      let currentObj = this.subProgressList[2];
      currentObj[0].isActive = false;
      currentObj[0].color = 'rgba(255, 255, 255, 0.3)';
      this.updateSubProgressList(currentObj);
      this.updateSelectedSubProgressIndex(1);
      let prevObj = this.subProgressList[1];
      this.updateApplicantProgress(30);
      prevObj[1].isActive = true;
      prevObj[1].color = '#54B7F9';
      prevObj[1].isCompleted = false;
      this.updateSubProgressList(prevObj);
      this.updateSelectedApplicantPrgressIndex(1);
      this.$router.push('/signup/email');
    },
    async goToNext() {
      try {
        const registerBrokerPayload = {
          mobilePhoneNumber: this.formData.mobile,
          email: this.brokerFormData.email,
          firstName: this.brokerFormData.firstName,
          middleName: this.brokerFormData.middleName,
          lastName: this.brokerFormData.lastName,
          otp: this.formData.otp,
        };
        if (!this.brokerFormData.brokerGroupId) {
          registerBrokerPayload.brokerGroup = {
            name: this.brokerFormData.brokerGroupName,
            aggregationGroup: this.brokerFormData.aggregationGroup,
            australianBusinessNumber: this.brokerFormData.australianBusinessNumber,
            primaryBusinessActivity: this.brokerFormData.primaryBusinessActivity,
            businessMobileNumber: this.brokerFormData.businessMobileNumber,
          };
        }
        const { data } = await _post(TECHLEND_BE_URL + API.AUTH.REGISTER_BROKER, registerBrokerPayload);
        await this.setLogin(data);
        this.isLoading = true;
        if (!this.v$.$invalid) {
          this.$toast.add({
            severity: 'success',
            summary: 'OTP verified successfully',
            life: 2000,
          });
          let currentObj = this.subProgressList[this.selectedSubProgressIndex];
          currentObj[0].isActive = false;
          currentObj[0].isCompleted = true;
          currentObj[0].color = '#54B7F9';
          this.updateSubProgressList(currentObj);
          this.updateApplicantProgress(77);
          this.updateSelectedSubProgressIndex(3);
          this.updateSelectedApplicantPrgressIndex(3);
          this.updateIsActiveValue({ index1: 3, index2: 0 });
          this.setFormData(this.formData);
          this.$router.push('/signup/licensing');
        }
        this.isLoading = false;
      } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
          await this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: error.response.data.message,
            life: 3000,
          });
        } else {
          await this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Something went wrong',
            life: 3000,
          });
        }
        this.isLoading = false;
      }
    },
  },
  computed: {
    ...mapGetters(['selectedSubProgressIndex', 'subProgressList']),
    ...mapGetters('brokerSignup', ['brokerFormData', 'isSignupResend']),
  },
  created() {
    this.formData.mobile = this.brokerFormData.mobile ? this.brokerFormData.mobile : '';
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
.title-text {
  color: #020038;
  font-size: 16px;
}
.col {
  padding: unset;
}
.w-410 {
  width: 410px;
}
.back {
  color: rgba(2, 0, 56, 0.4);
  font-weight: bold;
  font-size: 18px;
}
.send-code {
  top: 3.2rem;
  right: 10px;
  color: #54b7f9;
  font-weight: 600;
  font-size: 1rem;
}
</style>
