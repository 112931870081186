<template>
  <div>
    <Spinner class="flex align-items-center" v-if="isLoading" />
    <Card class="pl-3 pr-3" v-else>
      <template #content>
        <div class="justify-content-between account-overview" :class="isMD ? '' : 'flex'">
          <div class="col flex justify-content-between" :class="isMD ? '' : 'col-5'">
            <div class="title">Broker name</div>
            <div class="name">
              {{ profileDetails.firstName }}
              {{ profileDetails.middleName }}
              {{ profileDetails.lastName }}
            </div>
          </div>
          <div class="col flex justify-content-between" :class="isMD ? '' : 'col-5'">
            <div class="title">Broker number</div>
            <div class="name">{{ profileDetails.brokerReferenceNumber }}</div>
          </div>
        </div>
        <div class="title mt-3 ml-2">Personal information</div>
        <!-- First row -->
        <div class="flex" :class="isXS ? 'flex-column' : ''">
          <div class="col col-6" :class="isXS ? 'col-12' : ''">
            <div>Mobile</div>
            <TextField
              @onInput="getFormData($event, 'mobilePhoneNumber')"
              :value="formData.mobilePhoneNumber"
              type="number"
              width="100%"
              placeholder="Mobile"
              :disable="true"
            />
          </div>
          <div class="col col-6" :class="isXS ? 'col-12' : ''">
            <div>Email</div>
            <TextField
              @onInput="getFormData($event, 'email')"
              :value="formData.email"
              width="100%"
              placeholder="Email"
              :disable="true"
            />
          </div>
        </div>
        <!-- Second row -->
        <div class="flex" :class="isXS ? 'flex-column' : ''">
          <div class="col col-4" :class="isXS ? 'col-12' : ''">
            <div>Address line 1</div>
            <TextField
              placeholder="Address line 1"
              @onInput="getFormData($event, 'line1')"
              :value="formData.line1"
              width="100%"
            />
          </div>
          <div class="col col-4" :class="isXS ? 'col-12' : ''">
            <div>Address line 2</div>
            <TextField
              placeholder="Address line 2"
              @onInput="getFormData($event, 'line2')"
              :value="formData.line2"
              width="100%"
            />
          </div>
          <div class="col col-4" :class="isXS ? 'col-12' : ''">
            <div>City</div>
            <TextField placeholder="City" @onInput="getFormData($event, 'city')" :value="formData.city" width="100%" />
          </div>
        </div>
        <!-- Third row -->
        <div class="flex" :class="isXS ? 'flex-column' : ''">
          <div class="col col-4" :class="isXS ? 'col-12' : ''">
            <div>State</div>
            <Dropdown
              @change="getFormData($event, 'state')"
              :value="formData.state?.toUpperCase()"
              :items="statesList"
              placeholder="State"
              width="100%"
              :key="stateKey"
            />
          </div>
          <div class="col col-4" :class="isXS ? 'col-12' : ''">
            <div>Postal code</div>
            <Inputnumber
              class="mt-3"
              @onInput="getFormData($event, 'postCode')"
              :value="formData.postCode"
              width="100%"
              placeholder="Postal code"
              :key="postalKey"
              :maxDigit="3"
              :format="false"
            />
          </div>
          <div class="col col-4" :class="isXS ? 'col-12' : ''">
            <div>Country</div>
            <Dropdown
              width="100%"
              class="full-width"
              :items="[{ label: 'Australia', value: 'au' }]"
              optionLabel="label"
              optionValue="value"
              :value="formData.country"
              @change="getFormData($event, 'country')"
              placeholder="Country"
              :key="countryKey"
            />
          </div>
        </div>
        <div>
          <Button :disabled="isSubmitDisabled()" class="mt-3 ml-2" label="Save changes" type="primary" @click="save" />
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import { email, integer, maxLength, minLength, minValue, required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import Card from 'primevue/card';
// import { checkMobileNumber } from "../../../services/validatePhNo";
import { STATES } from '@/constants';
import { mapActions, mapGetters } from 'vuex';
import ScreenSize from '@/mixins/screensize.mixin';

export default {
  name: 'AccountComponent',
  mixins: [ScreenSize],
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      formData: {
        line1: { required },
        line2: { required },
        city: { required },
        state: { required },
        postCode: {
          required,
          integer,
          minLength: minLength(3),
          maxLength: maxLength(4),
          minValue: minValue(0),
        },
        country: { required },
        email: { email, required },
        // mobilePhoneNumber: {
        //   required,
        //   // checkMobileNumbers: (mobile) => {
        //   //   const result = checkMobileNumber(mobile);
        //   //   return result.isValid;
        //   // },
        // },
      },
    };
  },
  components: {
    Card,
  },
  data() {
    return {
      isLoading: false,
      formData: {
        line1: '',
        line2: '',
        city: '',
        state: '',
        postCode: '',
        country: '',
        mobilePhoneNumber: '',
        email: '',
      },
      statesList: STATES,
      stateKey: 0,
      countryKey: 0,
      postalKey: 20,
    };
  },
  computed: {
    ...mapGetters({
      profileDetails: 'settings/profileDetails',
    }),
  },
  methods: {
    ...mapActions({
      updateProfile: 'settings/updateProfile',
      getBrokerById: 'settings/getBrokerById',
    }),
    getFormData(value, fieldName) {
      if (fieldName === 'state') {
        this.formData[fieldName] = value.toLowerCase();
      } else {
        this.formData[fieldName] = value;
      }
      this.formData = { ...this.formData };
    },

    setFormData() {
      for (const [key, value] of Object.entries(this.profileDetails)) {
        this.formData[key] = value;
      }
      // this.stateKey += 1;
      // this.countryKey += 1;
    },

    isSubmitDisabled() {
      return this.v$.$invalid;
    },

    async save() {
      try {
        if (!this.v$.$invalid) {
          const data = Object.keys(this.formData).reduce(
            (acc, key) =>
              !this.formData[key] && typeof this.formData[key] !== 'boolean'
                ? acc
                : { ...acc, [key]: this.formData[key] },
            {},
          );
          data['postCode'] = `${data['postCode'] || ''}`;
          await this.updateProfile(data);
          await this.getBrokerById();
          this.$toast.add({
            severity: 'success',
            summary: 'Profile details updated successfully',
            life: 2000,
          });
        }
      } catch (err) {
        if (err.response && err.response.data && err.response.data.message) {
          await this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: err.response.data.message,
            life: 3000,
          });
        } else {
          await this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Something went wrong',
            life: 3000,
          });
        }
      }
    },
  },
  async created() {
    try {
      this.isLoading = true;
      await this.getBrokerById();
      await this.setFormData();
      this.isLoading = false;
    } catch (err) {
      console.log(err);
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
.p-card {
  box-shadow: unset !important;
  border-radius: 4px !important;
  color: $primary-text-color !important;
  font-family: $font-family !important;
  font-size: 1rem !important;
}
:deep(.p-card-title) {
  font-size: 1.25rem !important;
}
.account-overview {
  border-bottom: 1px solid rgba(2, 0, 56, 0.15);
}
.title {
  color: rgba(2, 0, 56, 0.3);
}
.flex-column {
  flex-direction: column;
}
.name {
  color: #020038;
  font-weight: 600;
}
:deep(.p-card-content) {
  font-size: 14px !important;
  color: #020038 !important;
  font-weight: 500;
}
.full-width {
  width: 100%;
}
</style>
