<template>
  <div>
    <h1 class="module-title">Name</h1>
    <div class="title-text bold-600 mt-3">
      Please enter your full legal name as it appears on your driver's license.
    </div>
    <TextField
      class="mt-3"
      width="100%"
      label="First name"
      @onInput="getFormData($event, 'firstName')"
      :value="formData.firstName"
      placeholder="First name"
    />
    <TextField
      class="mt-3"
      label="Middle name"
      width="100%"
      @onInput="getFormData($event, 'middleName')"
      :value="formData.middleName"
      placeholder="Middle name"
    />
    <TextField
      width="100%"
      class="mt-3"
      label="Last name"
      @onInput="getFormData($event, 'lastName')"
      :value="formData.lastName"
      placeholder="Last name"
    />
    <div class="flex align-items-center">
      <div @click="goNext">
        <Button
          class="mt-3"
          icon="pi pi-chevron-right"
          iconPos="right"
          label="Next"
          type="primary"
          :disabled="isSubmitDisabled()"
        />
      </div>
      <div class="back mt-3 ml-4 cursor-pointer" @click="goBack">Back</div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';

export default {
  name: 'Name',
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      formData: {
        firstName: { required },
        lastName: { required },
      },
    };
  },
  components: {},
  data() {
    return {
      formData: {
        firstName: '',
        lastName: '',
        middleName: '',
      },
    };
  },
  methods: {
    ...mapActions([
      'updateSubProgressList',
      'updateSelectedSubProgressIndex',
      'updateSelectedApplicantPrgressIndex',
      'updateIsActiveValue',
      'updateApplicantProgress',
    ]),
    ...mapActions({
      setFormData: 'brokerSignup/setFormData',
    }),
    isSubmitDisabled() {
      return this.v$.$invalid;
    },
    getFormData(value, fieldName) {
      this.formData[fieldName] = value;
      this.formData = { ...this.formData };
    },
    goNext() {
      if (!this.v$.$invalid) {
        let currentObj = this.subProgressList[this.selectedSubProgressIndex];
        currentObj[0].isCompleted = true;
        currentObj[0].isActive = false;
        currentObj[0].color = 'rgba(255, 255, 255, 0.3)';
        currentObj[1].isActive = true;
        currentObj[1].color = '#54B7F9';
        this.updateSubProgressList(currentObj);
        this.setFormData(this.formData);
        this.$router.push('/signup/email');
      }
    },
    async goBack() {
      let currentObj = this.subProgressList[1];
      currentObj[0].isActive = false;
      currentObj[0].color = 'rgba(255, 255, 255, 0.3)';
      this.updateSubProgressList(currentObj);
      this.updateSelectedSubProgressIndex(0);
      let prevObj = this.subProgressList[0];
      prevObj[0].isActive = true;
      prevObj[0].color = '#54B7F9';
      prevObj[0].isCompleted = false;
      this.updateSubProgressList(prevObj);
      this.updateSelectedApplicantPrgressIndex(0);
      this.updateApplicantProgress(6);
      this.$router.push('/signup/contract');
    },
  },
  computed: {
    ...mapGetters(['subProgressList', 'selectedSubProgressIndex']),
    ...mapGetters('brokerSignup', ['brokerFormData']),
  },
  created() {
    this.formData.firstName = this.brokerFormData.firstName ? this.brokerFormData.firstName : '';
    this.formData.middleName = this.brokerFormData.middleName ? this.brokerFormData.middleName : '';
    this.formData.lastName = this.brokerFormData.lastName ? this.brokerFormData.lastName : '';
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
.title-text {
  color: #020038;
  font-size: 16px;
}
</style>
