import { API, TECHLEND_BE_URL } from '@/constants.api';
import { TOKEN } from '@/constants';
import { _post } from '@/services/crudService';
import {
  applicationProgress,
  progressBarPercentage,
  referralApplicationProgress,
  referralProgressBarPercentage,
  referralSubProgressList,
  selectedApplicantPrgressIndex,
  // referralSelectedApplicantProgressIndex,
  selectedSubProgressIndex,
  subProgressList,
} from './constants';
import { createStore } from 'vuex';
import applications from './applications';
import brokerSignup from './brokerSignup';
import createPersistedState from 'vuex-persistedstate';
import dashboard from './dashboard';
import settings from './settings';

// import Vue from 'vue'
import { useCookies } from 'vue3-cookies';
const { cookies } = useCookies();

export default createStore({
  state: {
    brokerType: '',
    brokerId: '',
    isLoggedIn: false,
    isExistingBrokerCompany: true,
    token: '',
    mobile: '',
    applicationProgress: [
      { id: 1, title: 'Broker contracting party details', isActive: true },
      { id: 2, title: 'Personal details', isActive: false },
      { id: 3, title: 'Verify identify', isActive: false },
      { id: 4, title: 'Licensing/registration', isActive: false },
      { id: 5, title: 'Declaration', isActive: false },
    ],
    selectedApplicantPrgressIndex: 0,
    progressBarPercentage: 6,
    subProgressList: [
      [
        {
          id: 1,
          title: 'Contracting party details',
          isActive: true,
          isCompleted: false,
          color: '#2cdd80',
        },
      ],
      [
        {
          id: 1,
          title: 'Name',
          isActive: true,
          isCompleted: false,
          color: '#2cdd80',
        },
        {
          id: 2,
          title: 'Email',
          isActive: false,
          isCompleted: false,
          color: 'rgba(255, 255, 255, 0.3)',
        },
      ],
      [
        {
          id: 1,
          title: 'Mobile number verification',
          isActive: true,
          isCompleted: false,
          color: '#2cdd80',
        },
      ],
      [
        {
          id: 1,
          title: 'Licensing/registration details',
          isActive: true,
          isCompleted: false,
          color: '#2cdd80',
        },
      ],
      [
        {
          id: 1,
          title: 'Agreement',
          isActive: false,
          isCompleted: false,
          color: '#2cdd80',
        },
      ],
    ],
    selectedSubProgressIndex: 0,

    // referral signup
    referralApplicationProgress: [
      { id: 1, title: 'Personal details', isActive: true },
      { id: 2, title: 'Proof of identity', isActive: false },
      { id: 3, title: 'Licensing/registration', isActive: false },
      { id: 4, title: 'Payment details', isActive: false },
      { id: 5, title: 'Verify identify', isActive: false },
      { id: 6, title: 'Declaration', isActive: false },
    ],
    // referralSelectedApplicantProgressIndex: 0,
    referralProgressBarPercentage: 6,
    referralSubProgressList: [
      [
        {
          id: 1,
          title: 'Name',
          isActive: true,
          isCompleted: false,
          color: '#2cdd80',
        },
        {
          id: 2,
          title: 'Address',
          isActive: false,
          isCompleted: false,
          color: 'rgba(255, 255, 255, 0.3)',
        },
        {
          id: 3,
          title: 'Email',
          isActive: false,
          isCompleted: false,
          color: 'rgba(255, 255, 255, 0.3)',
        },
        {
          id: 4,
          title: 'Mobile',
          isActive: false,
          isCompleted: false,
          color: 'rgba(255, 255, 255, 0.3)',
        },
      ],
      [
        {
          id: 1,
          title: 'Proof of identity',
          isActive: true,
          isCompleted: false,
          color: '#2cdd80',
        },
      ],
      [
        {
          id: 1,
          title: 'Company Address',
          isActive: true,
          isCompleted: false,
          color: '#2cdd80',
        },
      ],
      [
        {
          id: 1,
          title: 'Account details',
          isActive: true,
          isCompleted: false,
          color: '#2cdd80',
        },
      ],
      [
        {
          id: 1,
          title: 'Mobile number verification',
          isActive: true,
          isCompleted: false,
          color: '#2cdd80',
        },
      ],
      [
        {
          id: 1,
          title: 'Decalaration',
          isActive: true,
          isCompleted: false,
          color: '#2cdd80',
        },
        {
          id: 2,
          title: 'Agreement',
          isActive: false,
          isCompleted: false,
          color: 'rgba(255, 255, 255, 0.3)',
        },
        {
          id: 3,
          title: 'Signature',
          isActive: false,
          isCompleted: false,
          color: 'rgba(255, 255, 255, 0.3)',
        },
      ],
    ],
    // referralSelectedSubProgressIndex: 0,
  },
  getters: {
    isExistingBrokerCompany: ({ isExistingBrokerCompany }) => isExistingBrokerCompany,
    brokerType: ({ brokerType }) => brokerType,
    token: ({ token }) => token,
    isLoggedIn: ({ isLoggedIn }) => isLoggedIn,
    applicationProgress: ({ applicationProgress }) => applicationProgress,
    progressBarPercentage: ({ progressBarPercentage }) => progressBarPercentage,
    subProgressList: ({ subProgressList }) => subProgressList,
    selectedSubProgressIndex: ({ selectedSubProgressIndex }) => selectedSubProgressIndex,
    selectedApplicantPrgressIndex: ({ selectedApplicantPrgressIndex }) => selectedApplicantPrgressIndex,

    // referral signup
    referralApplicationProgress: ({ referralApplicationProgress }) => referralApplicationProgress,
    referralProgressBarPercentage: ({ referralProgressBarPercentage }) => referralProgressBarPercentage,
    referralSubProgressList: ({ referralSubProgressList }) => referralSubProgressList,
    referralSelectedSubProgressIndex: ({ referralSelectedSubProgressIndex }) => referralSelectedSubProgressIndex,
    referralSelectedApplicantProgressIndex: ({ referralSelectedApplicantProgressIndex }) =>
      referralSelectedApplicantProgressIndex,
  },
  mutations: {
    /*
        updateSubProgressPaymentList(state, value) {
            if (!value) {
                state.subProgressList[1].push({
                    id: 4, title: 'Payment details', isActive: false, isCompleted: false, color: "rgba(255, 255, 255, 0.3)",
                })
            }
            else if (state.subProgressList[1].length === 4) {
                state.subProgressList[1].pop()
            }
        },
        */

    setIsExistingBrokerCompany(state, value) {
      state.isExistingBrokerCompany = value;
    },

    setLogin(state, value) {
      state.isLoggedIn = value ? true : false;
      state.token = value ? value : '';
      localStorage.setItem(TOKEN, state.token);

      const hostname = window.location.hostname;
      const domainParts = hostname.split('.');
      let baseDomain;
      if (domainParts.length < 2) {
        baseDomain = null;
      } else {
        const tld = domainParts.slice(-2).join('.');
        const secondLevelDomain = domainParts.slice(-3, -2).join('.');
        baseDomain = domainParts.length > 2 && tld.includes('.') ? `${secondLevelDomain}.${tld}` : tld;
      }
      if (baseDomain) {
        cookies.set(TOKEN, state.token, null, '/', baseDomain, true, 'None');
      } else {
        cookies.set(TOKEN, state.token);
      }
      if (!value) {
        localStorage.removeItem('vuex');
        cookies.remove(TOKEN);
        window.localStorage.clear();
        location.reload();
      }
    },
    setBrokerType(state, value) {
      state.brokerType = value;
    },

    async setLogout(state, { token }) {
      if (!token) {
        state.token = '';
        await localStorage.removeItem('vuex');
        await cookies.remove(TOKEN);
        window.localStorage.clear();
      }
    },

    updateApplicantProgress(state, value) {
      state.progressBarPercentage = value;
    },
    updateSubProgressList(state, value) {
      state.subProgressList[state.selectedSubProgressIndex] = value;
    },
    updateSelectedSubProgressIndex(state, value) {
      state.selectedSubProgressIndex = value;
    },
    updateSelectedApplicantPrgressIndex(state, value) {
      state.selectedApplicantPrgressIndex = value;
      state.applicationProgress[state.selectedApplicantPrgressIndex].isActive = true;
    },
    updateSelectedApplicantPrgressIndexArray(state, value) {
      state.applicationProgress.map((obj, i) => {
        if (i <= value) {
          obj.isActive = true;
        } else {
          obj.isActive = false;
        }
      });
    },
    updateIsActiveValue(state, { index1, index2 }) {
      state.subProgressList[index1][index2].isActive = true;
      state.subProgressList[index1][index2].color = '#2cdd80';
    },
    setInitialState(state) {
      state.applicationProgress = applicationProgress;
      state.progressBarPercentage = progressBarPercentage;
      state.subProgressList = subProgressList;
      state.selectedSubProgressIndex = selectedSubProgressIndex;
      state.selectedApplicantPrgressIndex = selectedApplicantPrgressIndex;
    },

    // referral signup
    referralUpdateApplicantProgress(state, value) {
      state.referralProgressBarPercentage = value;
    },
    referralUpdateSubProgressList(state, value) {
      state.referralSubProgressList[state.referralSelectedSubProgressIndex] = value;
    },
    referralUpdateSelectedSubProgressIndex(state, value) {
      state.referralSelectedSubProgressIndex = value;
    },
    referralUpdateSelectedApplicantPrgressIndex(state, value) {
      state.referralSelectedApplicantProgressIndex = value;
      state.referralApplicationProgress[state.referralSelectedApplicantProgressIndex].isActive = true;
    },
    referralUpdateSelectedApplicantPrgressIndexArray(state, value) {
      state.referralApplicationProgress.map((obj, i) => {
        if (i <= value) {
          obj.isActive = true;
        } else {
          obj.isActive = false;
        }
      });
    },
    referralUpdateIsActiveValue(state, { index1, index2 }) {
      state.referralSubProgressList[index1][index2].isActive = true;
      state.referralSubProgressList[index1][index2].color = '#2cdd80';
    },
    referralSetInitialState(state) {
      state.referralApplicationProgress = referralApplicationProgress;
      state.referralProgressBarPercentage = referralProgressBarPercentage;
      state.referralSubProgressList = referralSubProgressList;
      // state.referralSelectedSubProgressIndex = referralSelectedSubProgressIndex;
      // state.referralSelectedApplicantProgressIndex =
      //   referralSelectedApplicantProgressIndex;
    },
  },
  actions: {
    setIsExistingBrokerCompany({ commit }, value) {
      commit('setIsExistingBrokerCompany', value);
    },

    setLogout({ commit }, value) {
      commit('setLogout', value);
    },
    setLogin({ commit }, value) {
      commit('setLogin', value);
      commit('setInitialState');
    },

    updateApplicantProgress({ commit }, value) {
      commit('updateApplicantProgress', value);
    },
    updateSubProgressList({ commit }, value) {
      commit('updateSubProgressList', value);
    },
    updateSelectedSubProgressIndex({ commit }, value) {
      commit('updateSelectedSubProgressIndex', value);
    },
    updateSelectedApplicantPrgressIndex({ commit }, value) {
      commit('updateSelectedApplicantPrgressIndex', value);
    },
    updateIsActiveValue({ commit }, value) {
      commit('updateIsActiveValue', value);
    },
    setInitialState({ commit }) {
      commit('setInitialState');
    },
    updateSelectedApplicantPrgressIndexArray({ commit }, value) {
      commit('updateSelectedApplicantPrgressIndexArray', value);
    },
    //update sub progress list based on existing company
    /*
        updateSubProgressPaymentList({ commit }, value) {
            commit("updateSubProgressPaymentList", value)
        },
        */

    // referral signup
    referralSetLogin({ commit }, value) {
      commit('referralSetLogin', value);
      commit('referralSetInitialState');
    },
    referralUpdateApplicantProgress({ commit }, value) {
      commit('referralUpdateApplicantProgress', value);
    },
    referralUpdateSubProgressList({ commit }, value) {
      commit('referralUpdateSubProgressList', value);
    },
    referralUpdateSelectedSubProgressIndex({ commit }, value) {
      commit('referralUpdateSelectedSubProgressIndex', value);
    },
    referralUpdateSelectedApplicantPrgressIndex({ commit }, value) {
      commit('referralUpdateSelectedApplicantPrgressIndex', value);
    },
    referralUpdateIsActiveValue({ commit }, value) {
      commit('referralUpdateIsActiveValue', value);
    },
    referralSetInitialState({ commit }) {
      commit('referralSetInitialState');
    },
    referralUpdateSelectedApplicantPrgressIndexArray({ commit }, value) {
      commit('referralUpdateSelectedApplicantPrgressIndexArray', value);
    },

    // Fetch OTP
    async fetchOtp(context, payload) {
      await _post(TECHLEND_BE_URL + API.AUTHENTICATE.REQUEST_OTP, payload);
    },
    async verifyOTP({ commit }, payload) {
      const { data } = await _post(TECHLEND_BE_URL + API.AUTHENTICATE.VERIFY_OTP, payload);
      commit('setLogin', data.token);
      commit('setBrokerType', data?.type || 'broker');
    },
  },
  modules: {
    brokerSignup,
    settings,
    dashboard,
    applications,
  },
  plugins: [
    createPersistedState({
      storage: window.localStorage,
    }),
  ],
});
