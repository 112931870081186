<template>
  <div>
    <h1 class="module-title">Contracting party details</h1>
    <AutoComplete
      width="100%"
      :icon="dollar"
      label="Business/broker group name"
      @change="(e) => getSelectedValue(e)"
      :items="groupsList"
      :value="brokerFormData.brokerGroupName"
      placeholder="Business/broker group name"
    />
    <div v-if="!isExisting">
      <div class="mt-3" :class="isMD ? '' : 'flex'">
        <div class="col" :class="isMD ? 'col-12' : 'col-6'">
          <Dropdown
            width="95%"
            class="mt-3"
            label="Aggregation group"
            @change="getFormData($event, 'aggregationGroup')"
            :value="formData.aggregationGroup"
            :items="contractedAggregatorGroups"
            :option-label="(item) => item"
            placeholder="Your aggregation group"
          />
        </div>
        <div class="col" :class="isMD ? 'col-12 ml-0' : 'col-6 ml-2'">
          <TextField
            width="95%"
            class="mt-3"
            label="Business/broker group name"
            @onInput="getFormData($event, 'brokerGroupName')"
            :value="formData.brokerGroupName"
            placeholder="Your group name"
          />
        </div>
      </div>
      <div class="mt-2" :class="isMD ? '' : 'flex'">
        <div class="col" :class="isMD ? 'col-12' : 'col-6'">
          <div class="p-float-label label hk-grotest-medium mt-2">Business phone number</div>
          <TextField
            width="95%"
            class="mt-3"
            type="number"
            @onInput="getFormData($event ? $event.toString() : '', 'businessMobileNumber')"
            :value="formData.businessMobileNumber"
            placeholder="Your business phone number"
          />
        </div>
        <div class="col" :class="isMD ? 'col-12 ml-0' : 'col-6 ml-2'">
          <div class="p-float-label label hk-grotest-medium mt-2">ABN number</div>
          <TextField
            width="95%"
            class="mt-3"
            type="number"
            @onInput="getFormData($event ? $event.toString() : '', 'australianBusinessNumber')"
            :value="formData.australianBusinessNumber"
            placeholder="ABN Number"
          />
        </div>
      </div>
      <div class="mt-2" :class="isMD ? '' : 'flex'">
        <div class="col" :class="isMD ? 'col-12' : 'col-6'">
          <TextField
            width="95%"
            class="mt-3"
            label="Primary business activity"
            @onInput="getFormData($event, 'primaryBusinessActivity')"
            :value="formData.primaryBusinessActivity"
            placeholder="E.g. solicitor, accountant, etc"
          />
        </div>
      </div>
    </div>
    <div class="flex align-items-center">
      <Button class="mt-4" icon="pi pi-chevron-right" iconPos="right" label="Next" type="primary" @click="goToNext" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { maxLength, minLength, required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
// import { checkMobileNumber } from "../../../../services/validatePhNo";
import Dollar from '@/assets/search.svg';
import Dropdown from '@/components/common/Dropdown.vue';
import Screensize from '@/mixins/screensize.mixin';

export default {
  name: 'ContractDetails',
  mixins: [Screensize],
  components: { Dropdown },
  data() {
    return {
      dollar: Dollar,
      isExisting: true,
      formData: {
        aggregationGroup: '',
        brokerGroupName: '',
        numberOfBrokers: '',
        primaryBusinessActivity: '',
        businessMobileNumber: '',
        australianBusinessNumber: '',
      },
      groupsList: [],
    };
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    if (this.isExisting) {
      return {
        formData: {
          brokerGroupName: { required },
        },
      };
    } else {
      return {
        formData: {
          aggregationGroup: { required },
          brokerGroupName: { required },
          primaryBusinessActivity: { required },
          australianBusinessNumber: {
            required,
            minLength: minLength(11),
            maxLength: maxLength(11),
          },
          businessMobileNumber: {
            required,
            minLength: minLength(10),
            maxLength: maxLength(10),
            // checkMobileNumbers: (businessMobileNumber) => {
            //   const result = checkMobileNumber(businessMobileNumber);
            //   return result.isValid;
            // },
          },
        },
      };
    }
  },
  methods: {
    ...mapActions([
      'updateSubProgressList',
      'updateApplicantProgress',
      'updateSelectedSubProgressIndex',
      'updateSelectedSubProgressIndex',
      'updateSelectedApplicantPrgressIndex',
      'updateIsActiveValue',
      'setIsExistingBrokerCompany',
    ]),
    ...mapActions({
      setFormData: 'brokerSignup/setFormData',
      getBrokerGroups: 'brokerSignup/getBrokerGroups',
    }),
    isSubmitDisabled() {
      return this.v$.$invalid;
    },
    getFormData(value, fieldName) {
      this.formData[fieldName] = value;
      this.formData = { ...this.formData };
    },
    getSelectedValue(value) {
      if (!value) return;
      this.isExisting = value.id !== 0;
      this.formData.brokerGroupName = this.isExisting && value.brokerGroupName ? value.name : '';
      this.formData.aggregationGroup = this.isExisting && value.aggregationGroup ? value.aggregationGroup : '';
      this.formData.numberOfBrokers = this.isExisting && value.numberOfBrokers ? value.numberOfBrokers.toString() : '';
      this.formData.primaryBusinessActivity =
        this.isExisting && value.primaryBusinessActivity ? value.primaryBusinessActivity : '';
      this.formData.businessMobileNumber =
        this.isExisting && value.businessMobileNumber ? value.businessMobileNumber : '';
      this.setIsExistingBrokerCompany(this.isExisting);
    },
    goToNext() {
      if (!this.v$.$invalid) {
        this.setFormData(this.formData);
        let currentObj = this.subProgressList[0];
        currentObj[0].isActive = false;
        currentObj[0].isCompleted = true;
        currentObj[0].color = '#54B7F9';
        this.updateSubProgressList(currentObj);
        this.updateApplicantProgress(30);
        this.updateSelectedApplicantPrgressIndex(1);
        this.updateSelectedSubProgressIndex(1);
        this.updateIsActiveValue({ index1: 1, index2: 0 });
        this.$router.push('/signup/name');
      }
    },
  },
  computed: {
    ...mapGetters(['selectedSubProgressIndex', 'subProgressList', 'isExistingBrokerCompany']),
    ...mapGetters('brokerSignup', ['contractedAggregatorGroups', 'brokerFormData']),
  },
  async created() {
    this.formData.aggregationGroup = this.brokerFormData.aggregationGroup ? this.brokerFormData.aggregationGroup : '';
    this.formData.brokerGroupName = this.brokerFormData.brokerGroupName ? this.brokerFormData.brokerGroupName : '';
    this.formData.numberOfBrokers = this.brokerFormData.numberOfBrokers ? this.brokerFormData.numberOfBrokers : '';
    this.formData.primaryBusinessActivity = this.brokerFormData.primaryBusinessActivity
      ? this.brokerFormData.primaryBusinessActivity
      : '';
    this.formData.businessMobileNumber = this.brokerFormData.businessMobileNumber
      ? this.brokerFormData.businessMobileNumber
      : '';
    this.formData.australianBusinessNumber = this.brokerFormData.australianBusinessNumber
      ? this.brokerFormData.australianBusinessNumber
      : '';
    this.isExisting = this.isExistingBrokerCompany;
  },
};
</script>

<style lang="scss" scoped>
.title-text {
  max-width: 350px;
  color: #020038;
  font-size: 16px;
}
.col {
  padding: unset;
}
.back {
  color: rgba(2, 0, 56, 0.4);
  font-weight: bold;
  font-size: 18px;
}
</style>
