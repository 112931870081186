<template>
  <div>
    <h1 class="module-title">Company Address</h1>
    <div class="mt-3">
      <AddressAutocompleteKleberNew label="Business Address" @onChange="onAddressChange" />
    </div>
    <div v-if="formData.businessState">
      <TextField
        class="mt-3"
        width="100%"
        label="Street Address"
        placeholder="Address line 1"
        @onInput="getFormData($event, 'businessLine1')"
        :value="formData.businessLine1"
      />
      <TextField
        width="100%"
        placeholder="Address line 2"
        class="mt-2"
        @onInput="getFormData($event, 'businessLine2')"
        :value="formData.businessLine2"
      />

      <div class="flex mt-2">
        <div class="col col-6 mt-3">
          <TextField
            width="95%"
            label="City"
            @onInput="getFormData($event, 'businessCity')"
            :value="formData.businessCity"
            :disable="isCityDisabled"
            placeholder="City"
          />
        </div>
        <div class="ml-2 col col-6 mt-3">
          <Dropdown
            width="96%"
            @change="getFormData($event, 'businessState')"
            :value="formData.businessState"
            label="State"
            :items="states"
            :disable="true"
          />
        </div>
      </div>
      <div class="flex mt-2">
        <div class="col col-6 mt-3">
          <div class="p-float-label label hk-grotest-medium">Postal code</div>
          <Inputnumber
            width="95%"
            label="Postal code"
            type="number"
            @onInput="getFormData($event, 'businessPostCode')"
            :value="formData.businessPostCode"
            :disable="isPostalDisabled"
            placeholder="Postal code"
            :maxDigit="3"
            :format="false"
          />
        </div>
        <div class="ml-2 col col-6 mt-3">
          <Dropdown
            width="96%"
            label="Country"
            :items="['Australia']"
            @change="getFormData($event, 'businessCountry')"
            :value="formData.businessCountry"
            :disable="true"
          />
        </div>
      </div>
    </div>
    <div class="flex align-items-center">
      <Button
        class="mt-4"
        icon="pi pi-chevron-right"
        iconPos="right"
        label="Next"
        type="primary"
        @click="goToNext"
        :disabled="isSubmitDisabled()"
      />
      <div class="back mt-3 ml-4 cursor-pointer" @click="goBack">Back</div>
    </div>
  </div>
</template>

<script>
import { STATES } from '@/constants';
import { mapActions, mapGetters } from 'vuex';
import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import AddressAutocompleteKleberNew from '@/components/common/AddressAutoCompleteKleberNew.vue';

export default {
  name: 'Address',
  components: {
    AddressAutocompleteKleberNew,
  },
  data() {
    return {
      states: STATES,
      postCode: '',
      formData: {
        businessLine1: '',
        businessLine2: '',
        businessCity: '',
        businessState: '',
        businessPostCode: '',
        businessCountry: '',
      },
      isPostalDisabled: true,
      isCityDisabled: true,
    };
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      formData: {
        businessLine1: { required },
        businessCity: { required },
        businessState: { required },
        businessPostCode: { required },
        businessCountry: { required },
      },
    };
  },
  methods: {
    ...mapActions([
      'referralUpdateSubProgressList',
      'referralUpdateApplicantProgress',
      'referralUpdateSelectedSubProgressIndex',
      'referralUpdateSelectedSubProgressIndex',
      'referralUpdateSelectedApplicantPrgressIndex',
      'referralUpdateIsActiveValue',
    ]),
    ...mapActions({
      setReferralFormData: 'brokerSignup/setReferralFormData',
    }),
    isSubmitDisabled() {
      return this.v$.$invalid;
    },
    getFormData(value, fieldName) {
      this.formData[fieldName] = value;
      this.formData = { ...this.formData };
    },
    async onAddressChange(address) {
      this.postalcode = address.state;
      this.formData.businessLine1 = address.line1 ? address.line1 : '';
      this.formData.businessLine2 = address.line2 ? address.line2 : '';
      this.formData.businessCity = address.line1 ? address.city : '';
      this.formData.businessState = address.state ? address.state : '';
      this.formData.businessCountry = address.country ? address.country : '';
      if (address.city) {
        this.formData.businessCity = address.city;
        this.isCityDisabled = true;
      } else {
        this.formData.businessCity = '';
        this.isCityDisabled = false;
      }
      if (address.postCode) {
        this.formData.businessPostCode = Number(address.postCode);
        this.isPostalDisabled = true;
      } else {
        this.formData.businessPostCode = '';
        this.isPostalDisabled = false;
      }
    },
    goBack() {
      // let currentObj =
      //   this.referralSubProgressList[this.referralSelectedSubProgressIndex];
      // currentObj[0].isActive = true;
      // currentObj[0].color = "#54B7F9";
      // currentObj[0].isCompleted = false;
      // currentObj[1].isActive = false;
      // currentObj[1].color = "rgba(255, 255, 255, 0.3)";
      // this.referralUpdateSubProgressList(currentObj);
      // this.$router.push("/referral-signup/licensing");
      let currentObj = this.referralSubProgressList[2];
      currentObj[0].isActive = false;
      currentObj[0].color = 'rgba(255, 255, 255, 0.3)';
      this.referralUpdateSubProgressList(currentObj);
      this.referralUpdateSelectedSubProgressIndex(1);
      let prevObj = this.referralSubProgressList[1];
      prevObj[0].isActive = true;
      prevObj[0].color = '#54B7F9';
      prevObj[0].isCompleted = false;
      this.referralUpdateSubProgressList(prevObj);
      this.referralUpdateApplicantProgress(25);
      this.referralUpdateSelectedApplicantPrgressIndex(1);
      this.$router.push('/referral-signup/proof-of-identity');
    },
    goToNext() {
      let currentObj = this.referralSubProgressList[this.referralSelectedSubProgressIndex];
      currentObj[0].isCompleted = true;
      currentObj[0].isActive = false;
      currentObj[0].color = 'rgba(255, 255, 255, 0.3)';
      this.referralUpdateSubProgressList(currentObj);
      this.referralUpdateApplicantProgress(64);
      this.referralUpdateSelectedSubProgressIndex(3);
      this.referralUpdateSelectedApplicantPrgressIndex(3);
      this.referralUpdateIsActiveValue({ index1: 3, index2: 0 });
      this.setReferralFormData(this.formData);
      this.$router.push('/referral-signup/payment');
    },
  },
  computed: {
    ...mapGetters(['referralSelectedSubProgressIndex', 'referralSubProgressList']),
    ...mapGetters('brokerSignup', ['referralFormData']),
  },
  created() {
    this.formData.businessLine1 = this.referralFormData.businessLine1 ? this.referralFormData.businessLine1 : '';
    this.formData.businessLine2 = this.referralFormData.businessLine2 ? this.referralFormData.businessLine2 : '';
    this.formData.businessCity = this.referralFormData.businessCity ? this.referralFormData.businessCity : '';
    this.formData.businessState = this.referralFormData.businessState ? this.referralFormData.businessState : '';
    this.formData.businessPostCode = this.referralFormData.businessPostCode
      ? this.referralFormData.businessPostCode
      : 0;
    this.formData.businessCountry = this.referralFormData.businessCountry ? this.referralFormData.businessCountry : '';
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
.title-text {
  max-width: 350px;
  color: #020038;
  font-size: 16px;
}
.col {
  padding: unset;
}
.back {
  color: rgba(2, 0, 56, 0.4);
  font-weight: bold;
  font-size: 18px;
}
</style>
