<template>
  <Card>
    <template #title><div class="align-left module-sub-title">To-do</div> </template>
    <template #content>
      <Spinner v-if="isLoading" class="flex" />
      <div v-else>
        <div v-if="tasks.length === 0" class="todo-completed mt-6">
          <img :src="require('@/assets/todo_completed.svg')" alt />
          <p class="lt-txt-color font-size-1 mt-5">No tasks found</p>
        </div>
        <DataTable v-else :scrollable="true" scrollHeight="400px" dataKey="id" :value="tasks">
          <Column field="taskTitle" header="Applicant">
            <template #body="slotProps">
              <span v-if="slotProps.data.assignedToApplicant && slotProps.data.assignedToApplicant.firstName">{{
                slotProps.data.assignedToApplicant.firstName
              }}</span>
              <span
                v-if="slotProps.data.assignedToApplicant && slotProps.data.assignedToApplicant.lastName"
                class="ml-1"
                >{{ slotProps.data.assignedToApplicant.lastName }}</span
              >
            </template>
          </Column>
          <Column field="date" class="header-content" header="Action">
            <template #body="slotProps">
              <Button
                label="Action required"
                :color="'#ed653d'"
                style="font-size: 14px"
                @click="goToProgress(slotProps.data)"
              /> </template
          ></Column>
        </DataTable>
      </div>
    </template>
  </Card>
</template>

<script>
import { API, TECHLEND_BE_URL } from '@/constants.api';
import { _get } from '@/services/crudService';
import { mapActions } from 'vuex';
import Card from 'primevue/card';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';

export default {
  name: 'ToDo',
  components: {
    Card,
    DataTable,
    Column,
  },
  data() {
    return {
      tasks: [],
      isLoading: true,
    };
  },
  methods: {
    ...mapActions({
      setApplicationId: 'applications/setApplicationId',
      setLoanReferenceNumber: 'applications/setLoanReferenceNumber',
    }),
    async goToProgress(data) {
      await this.setApplicationId(data?.applicationId);
      await this.setLoanReferenceNumber(data?.application?.loanReferenceNumber);
      if (
        data?.application?.applicationStatus === 'cancelled' ||
        data?.application?.loanStatus === 'dropped_off' ||
        data?.application?.loanStatus === 'withdrawn' ||
        data?.application?.loanStatus === 'rejected'
      ) {
        this.$router.push(
          `/application/summary/${data?.application?.loanReferenceNumber || data?.applicationId}?progress=false`,
        );
      } else {
        this.$router.push(
          `/application/progress/${data?.application?.loanReferenceNumber || data?.applicationId}?progress=true`,
        );
      }
    },
  },
  async created() {
    this.isLoading = true;
    try {
      const result = await _get(TECHLEND_BE_URL + API.TASKS.LIST_TASKS + '?limit=1000');
      const unique = [];
      const uniqueTasks = [];
      for (const task of result?.data.tasks || []) {
        if (unique.indexOf(task.applicationId) === -1) {
          unique.push(task.applicationId);
          uniqueTasks.push(task);
        }
      }
      this.tasks = uniqueTasks;
      this.isLoading = false;
    } catch (err) {
      this.isLoading = false;
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
.p-card {
  box-shadow: unset !important;
  border-radius: 4px !important;
  color: $primary-text-color !important;
  font-family: $font-family !important;
  height: 100%;
  overflow-y: scroll;
}
:deep(.p-card-title) {
  font-size: 1.25rem !important;
  text-align: unset !important;
}
:deep(.p-card-content) {
  font-size: 14px !important;
}
.color-highlight {
  color: $primary-color !important;
}
:deep(.p-datatable .p-datatable-thead > tr > th) {
  background: $white-color;
  color: rgba(2, 0, 56, 0.3) !important;
  font-size: 1rem;
}
:deep(.p-datatable .p-datatable-tbody > tr > td) {
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #020038 !important;
  font-family: HK-medium;
}
:deep(.p-button) {
  background: rgba(254, 103, 27, 0.1) !important;
}
:deep(.p-datatable .p-datatable-tbody > tr > td) {
  text-align: left;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  padding: 1rem 0rem !important;
}
</style>
