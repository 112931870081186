<template>
  <div id="app">
    <Toast position="top-right" />
    <router-view></router-view>
  </div>
</template>

<script>
import Toast from 'primevue/toast';
export default {
  name: 'App',
  components: {
    Toast,
  },
  mounted() {
    const href = window.location.href;
    const envClass = href.includes('/staging-')
      ? 'env-staging'
      : href.includes('/dev-')
      ? 'env-dev'
      : href.includes('/localhost:')
      ? 'env-local'
      : null;

    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.head.appendChild(link);
    }
    link.href = `/favicon-${envClass || 'env-prod'}.png`;
  },
};
</script>

<style lang="scss">
@import '@/styles/global.scss';
#app {
  font-family: HK Grotesk !important;
}
</style>
